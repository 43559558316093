import {URL_BACKEND} from "../../../../../../../../../configs/url-config";

export type dataUsersErrorsType = {
    id: string,
    error: string
}

type Type = {
    successCallback: (data: dataUsersErrorsType[]) => void;
    errorCallback: () => void;
}

export const getUserErrorsAction = (
    {
        successCallback,
        errorCallback,
    }: Type
) => {
    fetch(`${URL_BACKEND}/vrcat/user/error/list`, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}})
        .then(function (response) {
            return response.json();
        })
        .then(function (res) {
            const tmp: dataUsersErrorsType[] = []
            const data = res.result.list;
            data.map((data: any) => {
                tmp.push({
                    id: data.id,
                    error: data.error,
                })
            })
            successCallback(tmp)
        })
        .catch(function (error) {
            console.log(error)
            errorCallback();
        })
}