import React, {useEffect, useState} from 'react';
import {MainCard} from "./styled";
import {InputBlock} from "../../../../../../../Popouts/VRChatPopouts/AddPlusUserPopouts/ui/InputBlock";
import EmptyBlock from "../../../../../VoronView/More/ContentBlock/Blocks/EmptyBlock/EmptyBlock";
import {ButtonUI} from "../../../../../../../../shared/ui/ButtonUI";
import {handlesaveAction} from "./modal/handlesaveAction";
import {getversionAction} from "./modal/getversionAction";

type VRCatBlockType = {};

const VRCatBlock: React.FC<VRCatBlockType> = () => {
    let oldData: any = null;
    const [title, setTitle] = useState("edit");
    const [isCheck, setIsCheck] = useState(true);
    const [version, setVersion] = useState("");
    const [des, setDes] = useState("");
    const [dow, setDow] = useState("");

    const onSave = () => {
        handlesaveAction({
            version,
            download: dow,
            description: des,
            onSuccessCallback: () => {
                setTitle("Saved!");
            },
            onErrorCallback: () => {}
        })
    }

    useEffect(() => {
        !version && !des && !dow && getversionAction({
            onSuccessCallback: e => {
                oldData = e;
                setDes(e.description);
                setVersion(e.version);
                setDow(e.download);
            },
            onErrorCallback: () => {}
        });
    }, []);

    useEffect(() => {
        if (oldData != null) {
            setIsCheck(oldData.version != version || oldData.description != des || oldData.download != dow);
        }
    }, [des, version, dow, oldData]);

    return (
        <MainCard>
            <InputBlock
                title={"version"}
                placeholder={"version"}
                onChange={setVersion}
                value={version}
            />
            <InputBlock
                title={"description"}
                placeholder={"description"}
                onChange={setDes}
                value={des}
            />
            <InputBlock
                title={"download"}
                placeholder={"download"}
                onChange={setDow}
                value={dow}
            />
            <ButtonUI
                // disabled={isCheck}
                text={title}
                color={"#B37FD9FF"}
                onClick={onSave}
            />

        </MainCard>
    );
};

export default VRCatBlock;