import React from "react";

import style from './TerminalBlock.module.css';

const TerminalBlock = (props) => {

    return (
        <div className={style.body + ' ' + style.off}>
            <span>{props.text}</span>
        </div>
    )
};

export default TerminalBlock;