import styled from "styled-components";

export const Body = styled.div<{color?: string, xl?: boolean, disabled?: boolean, bottom?: boolean}> `
  background: ${props => props.color ? props.color : "deepskyblue"};
  opacity: ${props => props.disabled ? "0.7" : "1"};
  pointer-events: ${props => props.disabled ? "none" : "auto"};
  width: ${props => props.xl ? '100%' : ""};
  height: 5%;
  border-radius: 20px;
  padding: 5px;
  color: white;
  position: ${props => props.bottom ? "absolute" : "unset"};
  bottom: ${props => props.bottom ? "10px" : "unset"};
  left: ${props => props.bottom ? "0" : "unset"};
  right: ${props => props.bottom ? "0" : "unset"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props?.disabled ? "unset" : "pointer"};
`;

export const EmptyBlock = styled.div`
  height: 100%;
`