import React, {useEffect, useState} from "react";
import ButtonItem from "./ButtonItem/ButtonItem";
import { Body } from "./styled";

type PageSwotchType = {
    maxPage: number;
    page: number;
    title: string;
    setPage: (page: number) => void;
}

const PageSwitch: React.FC<PageSwotchType> = (props) => {

    const [disabledNext, setDisabledNext] = useState(false);
    const [disabledBack, setDisabledBack] = useState(false);

    useEffect(() => {
        setDisabledNext(!(props.maxPage >= props.page + 1));
        setDisabledBack(!(props.page - 1 > -1));
    }, [props.page, props.maxPage]);

    function onNext() {
        if (props.maxPage >= props.page + 1) {
            props.setPage(props.page + 1);
        }
    }

    function onBack() {
        if (props.page - 1 > -1) {
            props.setPage(props.page - 1);
        }
    }

    return (
        <Body>
            <ButtonItem disabled={disabledBack} key={'back'} onClick={onBack} text={'<'} />
            <ButtonItem key={'count'} onClick={() => {}} text={disabledNext ? disabledBack ? props.title : props.page.toString() : props.page.toString()} />
            <ButtonItem disabled={disabledNext} key={'>'} onClick={onNext} text={'>'} />
        </Body>
    )
};

export default PageSwitch;