import React from "react";

import style from './EmptyBlock.module.css';

const EmptyBlock = (props) => {

    return(
        <div className={style.body}>
            <span >Выбирите пункт</span>
        </div>
    )
};

export default EmptyBlock;