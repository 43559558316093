import React from "react";
// @ts-ignore
import addIco from '../../../../../../../../shared/media/images/add.ico';
import {Body} from "./styled";

type FABButtonType = {
    onClick: () => void
}

const FABButton:React.FC<FABButtonType> = (props) => {

    return (
        <Body onClick={props.onClick} >
             <img src={addIco} alt={'FAB-ADD'} />
        </Body>
    )
};

export default FABButton;