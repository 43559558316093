export const firebaseConfigHB = {
    stableDataHB: 'stable/',
    shopsDataHB: 'shopsList/',
}

export const firebaseConfigVoron = {
    horsesDataVoron: '/horses',
    personalDataVoron: '/personal',
    tarningDataVoron: '/tranning',
    requestsDataVoron: '/request'
}