import styled from "styled-components";

export const Body = styled.div<{color?: string, on: boolean}>`
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  cursor: pointer;

  .back {
    height: 15px;
    width: 50px;
    border-radius: 20px;
    background: #e5e5e5;
  }

  .slider {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: ${props => props.color ? props.color : props.on ? '#f984ff' : 'rgba(221,201,246,0.8)'};
    right: ${props => props.on ? '0' : 'inherit'}} ;
    transition: 3s;
  }
`;

export const BlockSwitch = styled.div `
  width: calc(100% - 10px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0px 10px;
`;