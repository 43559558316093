import React from "react";
import TextAddShop from "../TextAddShop/TextAddShop";
import ImagesAddShop from "../imagesAddShop/ImagesAddShop";
import ButtonAddShop from "../ButtonAddShop/ButtonAddShop";

import style from './Slides.module.css';
import {textConfig} from "../../../../../../configs/text-config";

const Slide1 = (props) => {

    return (
        <div className={style.body}>
            <ImagesAddShop
                image={props.photo}
                key={props.name}
            />
            <TextAddShop
                title={true}
                text={props.name}
            />
            <TextAddShop
                text={props.web + ' ' + props.phone }
            />
            <TextAddShop
                text={props.email}
            />
            <TextAddShop
                text={props.des}
            />
            <div style={{display: 'flex'}}>
                <ButtonAddShop
                    text={textConfig.popoutsText.shopButtonsBack}
                    onClick={props.back}
                />
                <ButtonAddShop
                    text={textConfig.popoutsText.shopButtonNext}
                    onClick={props.next}
                />

            </div>
        </div>
    )
};

export default Slide1;