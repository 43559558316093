import React from 'react';
import {Body, EmptyBlock} from "./styled";

type Types = {
    text: string,
    className?: string;
    onClick?: () => void;
    xl?: boolean;
    color?: string;
    bottom?: boolean;
    disabled?: boolean;
}

export const ButtonUI: React.FC<Types> = (
    {
        text,
        onClick,
        xl,
        color,
        bottom,
        disabled,
        className,
    }
) => {

    return (
        <>
            <Body bottom={bottom} className={className} disabled={disabled} color={color} onClick={(e) => !disabled && onClick && onClick()} xl={xl}>
                <span>{text}</span>
            </Body>
        </>
    )
}