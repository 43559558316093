import React, {useState} from "react";
import Slide1 from "./ui/Slides/Slide1";
import Slide2 from "./ui/Slides/Slide2";

import style from './AddStablePopout.module.css';
import {textConfig} from "../../../../configs/text-config";
import imagesConfig from "../../../../configs/images-config";
import { StableDataUploadType} from "../../../../types/types";

type AddStablePopoutPropsType = {
    alert: (text: string, image: string) => void;
    addStable: (data: StableDataUploadType) => void;
    st?: string | boolean;
}

const AddStablePopout: React.FC<AddStablePopoutPropsType> = (props) => {

    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [des, setDes] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [cost, setCost] = useState("");
    const [note, setNote] = useState("");
    const [map1, setmap1] = useState("");
    const [map2, setmap2] = useState("");
    const [pic1, setPic1] = useState("");
    const [pic2, setPic2] = useState("");
    const [pic3, setPic3] = useState("");
    const [slide, setSlide] = useState(0)

    function nextSlide() {
        if (name && city &&  phone && des && address && email && cost && note && map1 && map2 && pic1 && pic2 && pic3) {
            if (slide === 0 )
                setSlide(slide + 1)
            else
                prepAddStable();

        }else {
            props.alert(textConfig.popoutsText.shopErrorEmpty, imagesConfig.empty);
        }
    }

    function prepAddStable() {
        let arrayStable : StableDataUploadType = {
            name: name,
            cost: cost,
            logo: '',
            map_1: map1,
            map_2: map2,
            note: note,
            pic1: pic1,
            pic2: pic2,
            pic3: pic3,
            sity: city,
            tel: phone,
            syte: address
        };

        props.addStable(arrayStable);
    }

    if (props.st === 'hbAddStable') {
        return (
            <div
                onClick={(e) => {e.stopPropagation()}}
                className={style.body}
            >
                {slide ? <Slide2
                    pic1={pic1} pic2={pic2} pic3={pic3}
                    name={name}
                    des={des}
                    phone={phone}
                    cost={cost}
                    address={address}
                    note={note}
                    map1={map1}
                    map2={map2}
                    back={() => setSlide(0)}
                    next={nextSlide}
                /> : <Slide1
                    name={name} setName={setName}
                    des={des} setDes={setDes}
                    city={city} setCity={setCity}
                    phone={phone} setPhone={setPhone}
                    address={address} setAdress={setAddress}
                    email={email} setEmail={setEmail}
                    note={note} setNote={setNote}
                    cost={cost} setCost={setCost}
                    map1={map1} setMap1={setmap1}
                    map2={map2} setMap2={setmap2}
                    pic1={pic1} setPic1={setPic1}
                    pic2={pic2} setPic2={setPic2}
                    pic3={pic3} setPic3={setPic3}
                    next={nextSlide}
                />}

            </div>
        );
    }else {
        return (<div />);
    }
};

export default AddStablePopout;