import React from "react";

import style from './RequestsList.module.css';
import RequestItem from "./RequestItem/RequestItem";

const RequestsList = (props) => {

    return (
        <div className={style.body}>
            {props.adapter.map((data) => (
                <RequestItem
                    name={data.name}
                    key={data.key}
                    tranning={data.tranning}
                    skill={data.skill}
                    phone={data.phone}
                />
            ))}
        </div>
    )
};

export default RequestsList;