import React from "react";
import SwitchItem from "./SwitchItem/SwitchItem";

import style from './SwitchPages.module.css';

const SwitchPages = (props) => {

    function onNext() {
        if (props.maxPage - 1 >= props.page) {
            props.setPage(props.page + 1);
        }
    }

    function onBack() {
        if (props.page - 1 > -1) {
            props.setPage(props.page - 1);
        }
    }

    return (
        <div className={style.body}>
            <SwitchItem text={'<'} onClick={onBack} />
            <SwitchItem text={'+'} onClick={props.add}/>
            <SwitchItem text={'>'} onClick={onNext} />
        </div>
    )
};

export default SwitchPages;