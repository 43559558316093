import React from 'react';

import style from './ImageShowShop.module.css';

const ImageShowShop = (props) => {

    return (
        <div  className={style.body}>
            <img src={props.image} alt={'imageShop'} />
        </div>
    )
};

export default ImageShowShop