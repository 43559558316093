import {combineReducers, configureStore} from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import alertReducer from './reducers/alertReducer';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import voronReducer from "./reducers/voronReducer";
import popoutsReducer from "./reducers/popoutsReducer";
import hbReducer from "./reducers/hbReducer";
import otherReducer from "./reducers/otherReducer";
import servicesDataReducers from "./reducers/servicesDataReducers";
import vrchatReducer from "./reducers/vrchatReducer";
import meReducer from "./reducers/meReducer";

const reducers = combineReducers({
    servicesData: servicesDataReducers,
    alert: alertReducer,
    voron: voronReducer,
    popout: popoutsReducer,
    hb: hbReducer,
    other: otherReducer,
    vrchat: vrchatReducer,
    me: meReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    // whitelist: ['alert',],
    // blacklist: ['task', 'proposal'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export type RootState = ReturnType<typeof store.getState>;