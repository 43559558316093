import styled from "styled-components";

export const Body = styled.div `
  background-color: white;
  border-radius: 20px 20px 0 0 ;
  width: 100vw;
  max-width: 600px;
  height: 200px;
  position: relative;
  text-align: center;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;