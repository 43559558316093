import styled from "styled-components";

export const Body = styled.div<{ isBlurPanel: boolean }>`
  height: 100%;
  background: aliceblue url("https://s1.1zoom.ru/b5242/803/Horses_White_532591_2048x1152.jpg") center;
  background-size: cover;
  position: relative;

  .panel {
    height: 35%;
    width: 50%;
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    display: grid;
    filter: ${ props => props.isBlurPanel ? `blur(8px)` : ''}
    grid-auto-rows: 25% 25% 25% 25%;
    left: 25%;
    margin: auto 0;
    border-radius: 20px;
    background: floralwhite;

    @media (max-width: 700px) {
        height: 50%;
        width: 100vw;
        max-width: unset;
        border-radius: 20px 20px 0 0;
        top: auto;
        left: auto;
    }
  }
`;