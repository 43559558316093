import styled from 'styled-components'

export const Body = styled.div<{numRow?: bigint}>`
  & {
    width: 20vw;
    height: auto;
    padding: 8px;
    border-radius: 0 10px 20px 0;
    background-color: #cdcdcd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-template-rows: repeat( 3,calc(100%/3))
  }

  @media (max-width: 900px) {
    & {
      width: 100%;
      height: 5vh;
      padding: 0;
      /*display: grid;*/
      /*grid-template-columns: calc(100%/5) calc(100%/5) calc(100%/5) calc(100%/5) calc(100%/5);*/
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      border-radius: 20px 20px 0 0;
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }
`