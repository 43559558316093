import React from "react";
import InputAddStable from "../InputAddStable/InputAddStable";
import ButtonAddStable from "../ButtonAddStable/ButtonAddStable";

import style from './Slides.module.css';

const Slide1 = (props) => {

    function onValidateMaps(text) {
        try {
            let data = text.split(',')
            if (data.length > 1) {
                props.setMap1(data[0])
                props.setMap2(data[1])
            }
        }catch (e) {

        }
    }

    function onValidatePhone(text) {
        const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if(regex.test(text)) {
            props.setPhone(text)

        }else {
            if (text.length < 13) {
                let tel = text
                //проверка на +7
                if (text.indexOf('+7') === -1) {
                    tel = '+7' + text
                }
                props.setPhone(tel)
            }
        }
    }
    
    function onValidateCost(text) {
       const regex = /^[0-9]+(\.[0-9]+)?$/
       if (regex.test(text)) {
           props.setCost(text)
       } else {
           if (text.length === 0) {
               props.setCost(0)
           }
       }
    }

    return (<div className={style.body}>
        <InputAddStable value={props.name} setValue={(text) => props.setName(text)} hint={'Name stable'} />
        <InputAddStable value={props.des} setValue={(text) => props.setDes(text)} hint={'Des'} paste={true}/>
        <InputAddStable value={props.city} setValue={(text) => props.setCity(text)} hint={'City'} />
        <InputAddStable value={props.phone} setValue={(text) => onValidatePhone(text)} paste={true} hint={'Phone'} type={'phone'} />
        <InputAddStable value={props.address} setValue={(text) => props.setAdress(text)} paste={true} hint={'Adress'} />
        <InputAddStable value={props.email} setValue={props.setEmail} paste={true} hint={'Email'} type={'email'} />
        <InputAddStable value={props.note} setValue={(text) => props.setNote(text)} hint={'Note'} />
        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
            <InputAddStable value={props.map1} setValue={(text) => onValidateMaps(text)} hint={'map1'} />
            <InputAddStable value={props.map2} setValue={(text) => props.setMap2(text)} hint={'map2'} />
            <InputAddStable value={props.cost} setValue={(text) => onValidateCost(text)} type={'number'} hint={'cost'} />
        </div>
        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
            <InputAddStable value={props.pic1} setValue={props.setPic1} hint={'pic1'} />
            <InputAddStable value={props.pic2} setValue={props.setPic2} hint={'pic2'} />
            <InputAddStable value={props.pic3} setValue={props.setPic3} hint={'pic3'} />
        </div>
        <ButtonAddStable text={'next'} onClick={props.next} />
    </div>)
};

export default Slide1;