import React, {useState} from "react";
import imagesConfig from "../../../../../configs/images-config";

import style from './TextShowStable.module.css';

const TextShowStable = (props) => {

    const [editStatus, setEditStatus] = useState(false);
    const [value, setValue] = useState('');

    function onChangeStatus() {
        setEditStatus(!editStatus);
        if (editStatus) {
            props.onChange(value);
        }
    }

    return (
        <div className={style.body}>
            {editStatus ?
                <input
                    value={value}
                    placeholder={props.text}
                    onChange={(e) => setValue(e.target.value)}
                />
                :
                props.title ?
                <h4>{props.text}</h4> :
                <span>{props.text}</span>
            }
            {props.title ? '' : <img
                src={editStatus ? imagesConfig.done : imagesConfig.edit}
                onClick={onChangeStatus} alt={'edit'}
            /> }
            {props.title ? editStatus ? <img
                src={props.edit ? imagesConfig.done : imagesConfig.del}
                onClick={onChangeStatus} alt={'edit'}
            /> : '' : ''}
        </div>

    )
};

export default TextShowStable;