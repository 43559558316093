import React from "react";
import imagesConfig from "../../../../../../../../../configs/images-config";

import style from './EmptyShop.module.css';

const EmptyShop = (props) => {

    return (
        <div className={style.body}>
            <img src={imagesConfig.empty} alt={'empty_image'} />
        </div>
    )
};

export default EmptyShop;