import React from 'react';
import { Body } from './styled';
import {dataUserPlusType} from "./model/getUsersPlus";
import {ListUserPlus} from "./ui/ListUserPlus";
import EmptyList from "../../UI/EmptyList/EmptyList";
import {dataUsersVrcatType} from "../WorldsBlock/model/getUsersApp";
import {ButtonUI} from "../../../../../../../../shared/ui/ButtonUI";

type Types = {
  users?: dataUserPlusType[];
  usersApp?: dataUsersVrcatType[];
  isApp?: boolean;
  onPage?: (page: number | string) => void;
  page?: string | number;
  onReverce?: () => void;
  isReverce?: boolean;
  onAll?: () => void;
}

export const PlusBlock: React.FC<Types> = (
    {
        users,
        usersApp,
        isApp,
        onPage,
        page,
        isReverce,
        onReverce,
        onAll,
    }
) => {
    return (
    <Body>
        {isApp && <div className={"actions-block-app"}>
            <ButtonUI xl onClick={onAll} className={"no-outline"} text={"Fetch all"} />
            <ButtonUI className={!isReverce ? "outline" : "no-outline"} onClick={onReverce} text={"Reverce"} />
        </div>}
        {!isApp ? users && users.length > 0 ? <ListUserPlus page={page} onPage={onPage} isApp={isApp} users={users}/> : <EmptyList onClick={() => {}}/> : ''}
        {/*@ts-ignore*/}
        {isApp ? usersApp && usersApp.length > 0 ? <ListUserPlus page={page} onPage={onPage} isApp={isApp} users={usersApp}/> : <EmptyList onClick={() => {}}/> : ''}
    </Body>
  )
}