import styled from "styled-components";

export const Body = styled.div`
  background-color: white;
  border-radius: 20px;
  width: 100vw;
  max-width: 750px;
  height: 40vh;
  margin-bottom: calc(50% - 30vh);
  text-align: center;
  position: relative;
  overflow: hidden;

  .avatar-img {
    z-index: 1;
  }

  @media (max-width: 600px) {
    margin-bottom: unset;
    border-radius: 20px 20px 0 0;
  }
  @media (min-width: 1240px) {
    margin-bottom: 300px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 180px;
  left: 0;
  top: 0;
  background-size: 180px;
  background: url("https://s1.1zoom.ru/b5242/803/Horses_White_532591_2048x1152.jpg") center content-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleUser = styled.div`
  width: calc(100% - 40px);
  margin: 10px 10px 0 10px;
  background: #a8a8a8;
  border-radius: 10px;
  padding: 10px;
  color: white;
  font-size: 20px;
`;

export const EmailUser = styled.div`
  width: calc(100% - 20px);
  margin: 10px 10px 0 10px;
  border-radius: 10px;
  color: #a8a8a8;
  font-size: 10px;
`;

export const LogoutButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: red;
  border-radius: 10px;
  width: 90px;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const GridServices = styled.div`
  display: flex;
  
`;