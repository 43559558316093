import {URL_BACKEND} from "../../configs/url-config";

type PropsI = {
    logoutSuc: () => void;
    logoutErr: () => void;
}

export const LogoutServerAction = ({logoutSuc, logoutErr}: PropsI) => {
    fetch(`${URL_BACKEND}/logout`)
        .then(function (response) {
            return response.json();
        })
        .then(function (res) {
            console.log(res)
            logoutSuc()
        })
        .catch(function (error) {
            console.log(error)
            logoutErr()
        })
}