import {AssetDataUploadType} from "../../../../types/types";
import React from "react";
import popoutsConfig from "../../../../configs/popouts-config";
import ModalBodyUI from "../../../../shared/ui/ModalBodyUI";
import Slide2 from "../AddAssetsPopout/More/Slides/Slide2";

type ShowAssetsPopoutPropsType = {
    asset: AssetDataUploadType;
    st?: string | boolean;
    onClose: () => void;
}

export const ShowAssetsPopout:React.FC<ShowAssetsPopoutPropsType> = ({st, asset, onClose}) => {

    if (st !== popoutsConfig.vrchatShowAsset) {
        return <div />;
    }

    return (
        <ModalBodyUI>
            <Slide2 onNext={onClose} isMy={asset?.tags?.indexOf("my") > -1} {...asset} />
        </ModalBodyUI>
    )
}