import React from "react";

import style from './ImagesAddStable.module.css';

const ImagesAddStable = (props) => {

    return (
        <div className={style.body}>
            <img src={props.pic1} alt={'demo-pic1'}/>
            <img src={props.pic2} alt={'demo-pic2'}/>
            <img src={props.pic3} alt={'demo-pic3'}/>
        </div>
    )
};

export default ImagesAddStable