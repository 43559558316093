import React, {useEffect, useState} from "react";
import ListCitys from './ListCitys/ListCitys';
import ListStables from "./ListStables/ListStables";
import SwitchPages from "./SwitchPages/SwitchPages";
import popoutsConfig from "../../../../../../../../configs/popouts-config";
import EmptyList from "./EmptyList/EmptyList";

import style from './StableBlock.module.css';
import {useDispatch, useSelector} from "react-redux";
import {selectHB} from "../../../../../../../../store/selectors";
import {setStatusPop} from "../../../../../../../../store/reducers";

const StableBlock = (props) => {

    const [stablesData, setStablesData] = useState(0)

    const data = useSelector(selectHB);
    const dispatch = useDispatch();

    const [sel, setSel] = useState(0);
    const [page, setPage] = useState(0);

    //узнаем макс страниц
    const maxPage = stablesData ? stablesData[0][sel] ? Math.floor(stablesData[0][sel].length/4) : 0 : 0

    useEffect(() => {
        try {
            //подготовка переменых
            let citys = [];
            let dataStables = [];

            //составление масива города
            for (let i = 0; i < data.stables.length; i++) {
                let idCity = data.stables[i].sity;
                if (citys.indexOf(idCity) === -1) {
                    //если города нету, то добавляем список и делаем масив для довления в него конюшен данного города
                    citys.push(idCity);
                    dataStables.push([]);
                }
            }

            //распределение конюшен
            for (let j = 0; j < data.stables.length; j++) {
                //узнаем id города
                let icity = data.stables[j].sity;
                icity = citys.indexOf(icity);
                //добавляем конюшню в массив города
                dataStables[icity].push(data.stables[j]);
            }

            if (dataStables.length > 0) {
                //@ts-ignore
                // const result: any = [dataStables, citys]
                setStablesData([dataStables, citys])
            }
            else {
                setStablesData(0)
            }

        } catch (e) {
            setStablesData(0)
        }
    }, [data.stables])

    return(
        <div className={style.body}>
            {stablesData ? <ListCitys sel={sel} list={stablesData[1]} setSel={(d) => {setPage(0); setSel(d);  }} /> : ''}
            {stablesData ? <ListStables
                show={(key) => dispatch(setStatusPop({data: {
                    type: popoutsConfig.hbShowStable,
                    data: stablesData[0][sel][page * 4 + key]
                }}))}
                list={stablesData[0]}
                selected={sel}
                page={page}
            /> : <EmptyList onClick={() => {}} />}
            {stablesData ? <SwitchPages page={page} add={() => dispatch(setStatusPop({data: {
                type: popoutsConfig.hbAddStable
            }}))} setPage={setPage} maxPage={maxPage}/> : ''}
        </div>
    )
};

export default StableBlock;