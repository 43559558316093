import React from "react";
import ButtonMenu from "./More/ButtonMenu/ButtonMenu";

import style from './MenuBlock.module.css';

const MenuBlock = (props) => {

    //left menu in horsebook view
    return (
        <div className={style.body}>
            <ButtonMenu text={'Лошади'} path={'horses'}/>
            <ButtonMenu text={'Персонал'} path={'personal'} />
            <ButtonMenu text={'Заявки'} path={'request'} />
        </div>
    )
};

export default MenuBlock;