import React from "react";

import style from './ShopList.module.css';
import ShopItem from "./ShopItem/ShopItem";

const ShopList = (props) => {

    let list = [];

    try {
        for (let i = 0; i < 4; i++) {
            list.push(props.list[props.page * 4 + i]);
        }
    } catch (e) {
        list = [];
    }

    return (
        <div className={style.body}>
            {list.map((data, index) => (
                data ? <ShopItem onClick={() => props.onClick(index)} name={data.name} image={data.ph}/> : ''
            ))}
        </div>
    )
};

export default ShopList;