import React from "react";
import addIco from '../../../../../../../../../shared/media/images/add.ico';

import style from './FABButton.module.css';

const FABButton = (props) => {

    return (
        <div className={style.body} onClick={props.onClick} >
             <img src={addIco} alt={'FAB-ADD'} />
        </div>
    )
};

export default FABButton;