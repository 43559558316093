import React, {useState} from "react";
import PageSwitch from "./PageSwitch/PageSwitch";
import ShopList from "./ShopList/ShopList";
import FABButton from "./FABButton/FABButton";
import popoutsConfig from "../../../../../../../../configs/popouts-config";
import EmptyShop from "./EmptyShop/EmptyShop";

import style from './ShopsBlock.module.css';
import {useDispatch, useSelector} from "react-redux";
import {selectHB} from "../../../../../../../../store/selectors";
import {setStatusPop} from "../../../../../../../../store/reducers";

const ShopsBlock = (props) => {

    const dispatch = useDispatch()
    const [page, setPage] = useState(0);
    const { shops } = useSelector(selectHB)
    const shopData = shops;
    //узнаю максимум страниц
    const maxPage = shopData ? Math.floor(shopData.length/4) : 0

    return (
        <div className={style.body}>
            <PageSwitch
                page={page}
                setPage={setPage}
                maxPage={maxPage}
            />
            {shopData ? <ShopList
                page={page}
                list={shopData}
                onClick={id => dispatch(setStatusPop({data:{
                    type: popoutsConfig.hbShowShop,
                    data: shopData[page * 4 + id],
                }}))}
            /> : <EmptyShop />}
            <FABButton onClick={() => dispatch(setStatusPop({data: {
                type: popoutsConfig.hbAddShop
            }}))} />
        </div>
    )
};

export default ShopsBlock;