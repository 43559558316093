import React from "react";

import {Body, Nav} from './styled';

type ButtonMenuType = {
    text: string,
    path: string,
}

const ButtonMenu:React.FC<ButtonMenuType> = ({text, path}) => {

    //Buttons for menu horsebook
    return (
        <Body>
            <Nav activeClassName={'activeMenu'} to={'/vrchat/' + path}>
                <h4>{text}</h4>
            </Nav>
        </Body>
    )
};

export default ButtonMenu;