import React from "react";

import style from './ButtonLogin.module.css';

const ButtonLogin = (props) => {

    return (
        <div className={style.body}>
            <div onClick={props.onClick}>
                <h4>{props.text}</h4>
            </div>
        </div>
    )
};

export default ButtonLogin;