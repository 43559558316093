import React, {useCallback, useEffect, useState} from 'react';
import ImageShowShop from "./More/ImageShowShop/ImageShowShop";
import TextShowShop from "./More/TextShowShop/TextShowShop";
import popoutsConfig from "../../../../configs/popouts-config";

import style from './ShowShopPopout.module.css';
import {InitStateShop} from "../../../../types/types";

type ShowShopPopoutPropsType = {
    st?: boolean | string;
    data: InitStateShop;
    onChange: (data: ShopViewType | boolean, key: string | undefined) => void;
}

export type ShopViewType = {
    image: string | boolean;
    des: string;
    name: string;
    tel: string;
    key: string | undefined;
    url: string;
    email: string;
}


const ShowShopPopout: React.FC<ShowShopPopoutPropsType> = (props) => {

    const [data, setData] = useState<ShopViewType | boolean>(false);

    const onChangeText = useCallback(() => {
        if (typeof data !== "boolean") {
            props.onChange(data, data.key);
        }
    }, [data, props] );

    const prepInfo = useCallback(() => {
        try {
            const data: ShopViewType = {
                key: props.data.key,
                name: props.data.name,
                des: props.data.des ? props.data.des : 'empty',
                image: props.data.ph ? props.data.ph : false,
                url: props.data.url ? props.data.url : '#',
                tel: props.data.tel ? props.data.tel : '#',
                email: props.data.email ? props.data.email : '#',
            }

            return data;

        }catch (e) {
            console.warn('ShowShowPopouts.js> prepInfo() error in preparation');
            return false;
        }
    }, [props.data]);

    useEffect(() => {
        setData(prepInfo())
    }, [prepInfo])

    if (props.st === popoutsConfig.hbShowShop){

        return typeof data !== 'boolean' ? (
            <div className={style.body} onClick={(e) => {e.stopPropagation()}}>
                <ImageShowShop
                    image={data.image}
                />
                <TextShowShop
                    title={true}
                    text={data.name}
                    onChange={(text: string) => {
                        if (typeof data !== "boolean") {
                            data.name = text
                        }
                        onChangeText()
                    }}
                />
                <TextShowShop
                    text={data.des}
                    onChange={(text: string) => {
                        if (typeof data !== "boolean") {
                            data.des = text
                        }
                        onChangeText()
                    }}
                />
                <TextShowShop
                    text={data.url}
                    onChange={(text: string) => {
                        if (typeof data !== "boolean") {
                            data.url = text
                        }
                        onChangeText()
                    }}
                />
                <TextShowShop
                    text={data.tel}
                    onChange={(text: string) => {
                        if (typeof data !== "boolean") {
                            data.tel = text
                        }
                        onChangeText()
                    }}
                />
                <TextShowShop
                    text={data.email}
                    onChange={(text: string) => {
                        if (typeof data !== "boolean") {
                            data.email = text
                        }
                        onChangeText()
                        
                    }}
                />


            </div>
        ) : (<div />)
    }else {
        return (<div />)
    }
};

export default ShowShopPopout