import React, {useEffect, useState} from "react";
import storeConfig from "../../../../../../../../configs/store-config";
import HorsesList from "./HorsesList/HorsesList";
import PageSwitch from "../HorsesBlock/PageSwitch/PageSwitch";

import style from "./HorsesBlock.module.css"
import EmptyList from "./HorsesList/EmptyList/EmptyList";
import {useDispatch, useSelector} from "react-redux";
import {selectVoron} from "../../../../../../../../store/selectors";
import {setStatusPop} from "../../../../../../../../store/reducers";

const HorsesBlock = (props) => {
    const [page, setPage] = useState(0);

    //данные лошадей
    const data = useSelector(selectVoron);
    const dispatch = useDispatch();

    //узнаю максимум страниц
    const maxPage = data.horses ? Math.floor(data?.horses?.length / 4) : 0;

    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <div className={style.body}>
            <PageSwitch
                page={page}
                setPage={setPage}
                maxPage={maxPage}
            />
            {data.horses.length > 0 ? <HorsesList
                page={page}
                list={data.horses}
                onClick={id => dispatch(setStatusPop({
                    type: storeConfig.showHorseVoron,
                    data: page * 4 + id,
                }))}
            /> : <EmptyList onClick={() => {}} />}

        </div>
    )
};

export default HorsesBlock;