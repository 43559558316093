import React from "react";

import style from './TextLogin.module.css';

const TextLogin = (props) => {

    return (
        <div className={style.body}>
            <span>{props.text}</span>
        </div>
    )
};

export default TextLogin;