
import React, {useEffect, useState} from "react";
import style from "../../../../../VoronView/More/ContentBlock/Blocks/HorsesBlock/HorsesBlock.module.css";
import PageSwitch from "../../UI/PageSwitch";
import EmptyList from "../../UI/EmptyList/EmptyList";
import {useDispatch, useSelector} from "react-redux";
import {loginVRchat} from "../../../../../../../../store/other/loginVRchat";
import {selectVRChat} from "../../../../../../../../store/selectors";
import {AssetsList} from "./AssetsList";
import FABButton from "../../UI/FABButton";
import {setStatusPop} from "../../../../../../../../store/reducers";
import popoutsConfig from "../../../../../../../../configs/popouts-config";

const AssetsBlock = () => {
    const [page, setPage] = useState(0);

    const dispatch = useDispatch();
    const {assets} = useSelector(selectVRChat);

    useEffect(() => {
        loginVRchat(dispatch)
    }, [dispatch])

    //узнаю максимум страниц
    const maxPage = assets ? Math.floor(assets.length / 4) : 0

    return (
        <div className={style.body}>
            <PageSwitch
                title={'Assets'}
                page={page}
                setPage={setPage}
                maxPage={maxPage}
            />
            {assets.length > 0 ? <AssetsList page={page} list={assets} /> : <EmptyList onClick={() => {}} />}
            <FABButton onClick={() => dispatch(setStatusPop({data: {status: true, type: popoutsConfig.vrchatAddAsset}}))} />
        </div>
    )
};

export default AssetsBlock;