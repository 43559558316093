import React from "react";
import {textConfig} from "../../../../../../../../../../configs/text-config";
import imagesConfig from "../../../../../../../../../../configs/images-config";

import style from './RequestItem.module.css';

const RequestItem = (props) => {

    function tel() {
        //open telephone
        window.open('tel:' + props.phone);
    }

    return (
        <div className={style.body}>
            <h4>{props.key + ' ' + props.name}</h4>
            <span>{textConfig.voronText.requestListItemTarnning + props.tranning}</span>
            <div className={style.buttons}>
                <img src={imagesConfig.phoneGradient} alt={props.key && '_tel'} onClick={tel} />
                <img src={imagesConfig.copy} alt={props.key && '_copy'} />
            </div>
        </div>
    )
};

export default RequestItem;