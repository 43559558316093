import ButtonMenu from "./ButtonMenu";
import {Body} from './styled';
import React from "react";

const MenuBlock = () => {

    //left menu in horsebook view
    return (
        <Body>
            <ButtonMenu text={'Пользователи'} path={'users'}/>
            <ButtonMenu text={'Ассеты'} path={'assets'} />
            <ButtonMenu text={'Plus'} path={'plus'} />
            <ButtonMenu text={'VRCat'} path={'vrcat'} />
            <ButtonMenu text={'Errors'} path={'errors'} />
            {/*<Index text={'Заявки'} path={'request'} />*/}
        </Body>
    )
};

export default MenuBlock;