import React from 'react';
import {Body, BodyErrorList} from "./styled";
import {dataUsersErrorsType} from "../WorldsBlock/model/getError";
import EmptyList from "../../UI/EmptyList/EmptyList";
import ItemError from "./ui/ItemError";

type ErrorsBlockType = {
    data: dataUsersErrorsType[],
};

const ErrorsBlock: React.FC<ErrorsBlockType> = ({data}) => {

    return (
        <Body>
            <BodyErrorList>
                {data.length > 0
                    ? data.map((data: dataUsersErrorsType) => (
                        <ItemError id={data.id} error={data.error}/>
                    ))
                    : <EmptyList/>
                }
            </BodyErrorList>
        </Body>
    );
};

export default ErrorsBlock;