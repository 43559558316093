import React from "react";
import {Redirect, Route} from 'react-router-dom';
import LoginView from "./Views/LoginView/LoginView";
import HorseBookView from "./Views/HorseBookView/HorseBookView";
import VoronView from "./Views/VoronView/VoronView";
import VRChatView from "./Views/VRChatView";
import LimboBotView from "./Views/LimboBotView/LimboBotView";
import {LogoutView} from "./Views/LogoutView";
import {HorseVET} from "./Views/HorseVET";

import style from './Content.module.css';

const Content = ({props}) => {


    return(
        <div className={style.body}>
            <Route exact path={'/'} ><LoginView props={props} /></Route>
            <Route exact path={'/regip'} ><LoginView props={props} /></Route>
            <Route path={'/horsebook'}><HorseBookView props={props} /></Route>
            <Route path={'/voron'}><VoronView props={props} /></Route>
            <Route path={'/LimboBot'}><LimboBotView props={props} /></Route>
            <Route path={'/vrchat'}><VRChatView props={props} /></Route>
            <Route path={'/horsevet'}><HorseVET /></Route>
            <Route path={'/logout'}><LogoutView /></Route>

        </div>
    )
};

export default Content;