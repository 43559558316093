import React from 'react';

import style from './ButtonItem.module.css';
import { Body } from './styled';

type CityItemType = {
    key: string,
    text: string,
    onClick: () => void;
    disabled?: boolean;
}

const ButtonItem: React.FC<CityItemType> = (props) => {

    return (
        <Body disabled={props.disabled} id={props.key} onClick={() => {props.onClick()}} key={props.key}>
            <span key={props.key + '_span'}>{props.text}</span>
        </Body>
    )
};

export default ButtonItem;