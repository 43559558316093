import React, {useState} from "react";

import style from './InputAddStable.module.css';

const InputAddStable = (props) => {


    return (
        <div className={style.body}>
            <input
                min="3"
                pattern={props.pattern}
                value={props.value}
                type={props.type}
                placeholder={props.hint}
                onChange={
                    (e) => props.setValue(e.target.value)
                }
            />
            {props.paste ? <div onClick={
                () =>props.value ? props.setValue('') : navigator.clipboard.readText().then((text) => {
                    props.setValue(text);
                })} className={style.paste}><span>{props.value ? 'clear' : 'Paste'}</span></div> : ''}
        </div>
    )
};

export default InputAddStable;