import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setStatusPop} from "../../store/reducers";
import {selectMe, selectOther, selectServicesData} from "../../store/selectors";
import {getLogoHeader} from "./modal/getLogo";
import {Body} from "./styled";

const Header = () => {

    const dispatch = useDispatch();
    const { serviceSelected } = useSelector(selectOther);
    const { avatar, name } = useSelector(selectMe);
    const {servicesProp} = useSelector(selectServicesData);

    function onImageClick() {
        servicesProp && dispatch(setStatusPop({data: {
            type: 'services'
        }}));
    }

    function onAvatarClick() {
        servicesProp && dispatch(setStatusPop({data: {
                type: 'profile'
            }}));
    }

    return (
        <Body color={serviceSelected == "HorseVet" ? "#2ed7be" : "floralwhite"}>
            <img onClick={onImageClick} src={getLogoHeader(serviceSelected || "")}  alt={'logo'}/>
            {avatar && name && <div className={'block-user'}>
                <span className={'title-user'}>{name}</span>
                <img onClick={onAvatarClick} className={'img-avatar'} src={avatar}/>
            </div>}
        </Body>
    )
};

export default Header;