import React from "react";

import style from './InputLogin.module.css';

const InputLogin = (props) => {


    return (
        <div className={style.body}>
            <input value={props.text} onChange={(event => {props.set(event.target.value)})} type={props.type ? props.type : ''} placeholder={props.hint} />
        </div>
    )
};

export default InputLogin;