import style
    from "../../../../../../../VoronView/More/ContentBlock/Blocks/HorsesBlock/HorsesList/HorsesItem/HorseItem.module.css";
import React from "react";
import favImg from '../../../../../../../../../../shared/media/images/fav.svg';
import {Body} from "./styled";

type ItemAssetType = {
    name: string,
    image: string,
    key: number,
    fav: string,
    onClick?: () => void,
    onEdit?: () => void,
}

const ItemWorld:React.FC<ItemAssetType> = (props) => {

    return (
        <Body>
            <div
                key={props.key + '_card'}
                className={"card"}
                style={{backgroundImage: 'url(' + props.image + ')'}}
                onClick={props.onClick}
            >
                <div onClick={props.onEdit} className={'edit'}><span>Edit</span><img src={'https://www.shareicon.net/data/2015/09/15/101670_edit_512x512.png'} /></div>
                <div className={'fav'}><img src={favImg} />{props.fav}</div>
                <div key={props.key + '_bg'} className={"bg"}>
                    <span key={props.key + '_span'}>{props.name}</span>
                </div>
            </div>
        </Body>
    )
};

export default ItemWorld;