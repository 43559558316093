import React, {useCallback, useState} from 'react';
import {Body} from './styled';
import popoutsConfig from "../../../../configs/popouts-config";
import {InputBlock} from "./ui/InputBlock";
import ButtonLogin from "../../../Content/Views/LoginView/ui/ButtonLogin/ButtonLogin";
import {Button} from "./ui/Button";
import {
    addPlusUserAction
} from "../../../Content/Views/VRChatView/More/ContentBlock/Blocks/PlusBlock/model/addUserPlus";
import {useSelector} from "react-redux";
import {selectOther} from "../../../../store/selectors";
import TextAddShop from "../../HorseBookPopouts/AddShopPopouts/More/TextAddShop/TextAddShop";
import {ButtonUI} from "../../../../shared/ui/ButtonUI";

type Types = {
    st?: string | boolean;
    close: () => void;
    err?: () => void;
}

export const AddPlusUserPopouts: React.FC<Types> = (
    {
        st,
        close,
        err,
    }
) => {
    const [error, setError] = useState("");
    const [valueId, setValueId] = useState('');
    const [valueName, setValueName] = useState('');

    const addUser = useCallback(() => {
        addPlusUserAction({
            name: valueName,
            id: valueId,
            successCallback: () => close(),
            errorCallback: () => {
                err && err();
                setError("Что-то пошло не так!");
            }
        })
    }, [valueId, valueName]);

    if (st !== popoutsConfig.vrchatAddPlus) {
        return <div/>
    }

    return (
        <Body onClick={e => e.stopPropagation()}>
            <InputBlock
                title={'id'}
                value={valueId}
                onChange={setValueId}
            />
            <InputBlock
                title={'name'}
                value={valueName}
                onChange={setValueName}
            />
            {error.length > 0 && <TextAddShop title={error}/>}
            <ButtonUI bottom text={'Добавить'} onClick={addUser} />
        </Body>
    )
}