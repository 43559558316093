import React from "react";

import style from './SwitchItem.module.css';

const SwitchItem = (props) => {

    return (
        <div className={style.body}>
            <div className={style.card} onClick={props.onClick}>
                <span>{props.text}</span>
            </div>
        </div>
    )
};

export default SwitchItem;