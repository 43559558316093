import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ServicesSelectedType} from "../../types/types";

type initialStateType = {
    name: false | string,
    email: false | string,
    services: false | string,
    avatar: false | string,
}

const initialState: initialStateType = {
    name: false,
    email: false,
    services: false,
    avatar: false,
};

const meSlice = createSlice({
    name: 'me',
    initialState,
    reducers: {
        setMe:  (state, action: PayloadAction<{ meData: initialStateType }>) => {
            const {name, avatar, email} = action.payload.meData
            return {
                services: false,
                name: name,
                avatar: avatar,
                email: email
            };
        },
    },
});

export const {setMe} = meSlice.actions;

export default meSlice.reducer;