const storeConfig = {
    showShop: 'SHOW-SHOPHB',
    showHorseVoron: 'SHOW-HORSEVORON',
    getHorsesVoron: 'GET-HORSESVORON',
    getPersonalVoron: 'GET-PERSONALVORON',
    getRequestVoron: 'GET-REQUESTVORON',
    copyIp: "COPY-IP",
    addStableHB: 'ADD-STABLE',
    addShopHB: 'ADD-SHOP',
    updateStableHB: 'UPDATE-STABLE',
    openPop: 'OPEN-POP',
    closePop: 'CLOSE-POP',
    openService: 'HEADER-IMAGE',
    getLimboBotStatus: 'LIMBOBOT-STATUS',
    switchLimboBotStatus: 'LIMBOBOT-SWITCH',
    getServiceList: 'GET-SERVICE-LIST',
    getSevices: 'GET-SERVICE',
    getAlertBanner: 'GET-ALERTBANNER',
    registerHB: 'REGISTER-HB',
    registerVoron: 'REGISTER-VORON',
    getStablesHB: 'GET-STABLES',
    getShopsHB: 'GET-SHOPS',
    getVideoBannerStatus: 'GET-VIDEOSIT',
    switchVideoBannerStatus: 'SWITCH-VIDEOSIT',
    getPop: 'GET-POP',
    loginApp: 'LOGIN-APP',
    setAlertBanner: 'SET-ALLERTBANNER',
    updateDisplay: 'UPDATE-DISPLAY',
}

export default storeConfig;