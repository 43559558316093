import React from "react";

import style from './ImagesAddShop.module.css';

const ImagesAddShop = (props) => {

    return (
        <div className={style.body}>
            <img src={props.image} alt={'image shop_' + props.key} />
        </div>
    )
};

export default ImagesAddShop;