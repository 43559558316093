import React from "react";

import style from './ImageShowStable.module.css';

const ImageShowStable = (props) => {

    return (
            <div className={style.body}>
                <div className={style.empty} />
                <img src={props.pic1} alt={'pic1'} />
                <img src={props.pic2} alt={'pic2'} />
                <img src={props.pic3} alt={'pic3'} />
            </div>
    )
};

export default ImageShowStable;