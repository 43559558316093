import errorLottie from '../shared/media/lotties/error.json';
import secLottie from '../shared/media/lotties/ips.json';
import copyLottie from '../shared/media/lotties/copy.json';
import emptyLottie from '../shared/media/lotties/empty.json';
import succesLottie from '../shared/media/lotties/succes.json';


const lottieConfig = {
    errorConfig: {
        loop: false,
        autoplay: true,
        animationData: errorLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    },
    securityConfig: {
        loop: false,
        autoplay: true,
        animationData: secLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    },
    copyConfig: {
        loop: false,
        autoplay: true,
        animationData: copyLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    },
    emptyConfig: {
        loop: false,
        autoplay: true,
        animationData: emptyLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    },
    succesConfig: {
        loop: false,
        autoplay: true,
        animationData: succesLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    },
};

export default lottieConfig;