import firebaseData from "../../../../../backend/firebase";
import {firebaseConfigHB} from "../../../../../configs/firebase-config";
import stateConfig from "../../../../../configs/store-config";
import {StableDataUploadType} from "../../../../../types/types";

type PropsI = {
    action: { data: StableDataUploadType };
    suc: () => void;
    err: (err: string) => void;
}

export const addStableAction = ({action, suc, err}: PropsI) => {
    firebaseData.push(firebaseConfigHB.stableDataHB, action.data,
        () => suc(),
        () => err('Error push data'))
};