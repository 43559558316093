export const getTagsBack: ({tags}: { tags: string | undefined }) => (string[]) = ({tags}: {tags: string | undefined}) => {
    if (!!tags?.length) {
        try {
            if (typeof JSON.parse(tags) === "string") {
                return [tags]
            } else {
                return JSON.parse(tags);
            }
        } catch (e) {
            return [tags]
        }
    }

    return [tags]
}