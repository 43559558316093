import React from "react";
import lottieConfig from "../../../../../../../../../configs/lottie-config";
import Lottie from "react-lottie";

import style from './EmptyList.module.css'

const EmptyList = (props) => {

    return(
        <div className={style.body}>
            <div className={style.emptyImage}>
                {/*<img src={imagesConfig.emptyGradient} alt={'empty'} />*/}
                <Lottie
                    width={'100%'}
                    height={'100%'}
                    options={lottieConfig.emptyConfig}
                />
            </div>
            <h4 onClick={props.onClick}>{'Update'}</h4>
        </div>
    )
};

export default EmptyList;