import React, {useEffect, useLayoutEffect, useState} from "react";
import Content from "./data/Componets/Content/Content";
import Header from "./data/Componets/Header/Header";
import Popouts from "./data/Componets/Popouts/Popouts";
import AlertBanner from "./data/Componets/ AlertBanner/AlertBanner";

import './App.css';
import {InitStateServicesType, PropsType} from "./data/types/types";
import {loginAppAuto} from "./data/store/other/loginApp";
import {useDispatch, useSelector} from "react-redux";
import {selectOther, selectPopout, selectServicesData} from "./data/store/selectors";
import {useHistory} from "react-router-dom";
import {setStatusPop} from "./data/store/reducers";
import popoutsConfig from "./data/configs/popouts-config";
function App(props: PropsType) {

    // const root: InitStateType = useSelector(selectRoot);
    // console.log(root)
    const [status, setStatus] = useState("loading");

    const history = useHistory();
    const dispatch = useDispatch();
    const other = useSelector(selectOther);
    const services: InitStateServicesType = useSelector(selectServicesData);
    const popout = useSelector(selectPopout);


    useEffect(() => {
        loginAppAuto({
            services: services,
            popout: popout,
            dispatch: dispatch,
            other: other,
            history: history,
            err: () => {
                history.push("/");
                setStatus("failed");
            },
            success: () => {
                setStatus("success");
                console.log(other.urlSave)
                if (window.location.pathname === "/") {
                    dispatch(setStatusPop({
                        data: {
                            type: popoutsConfig.services,
                        }
                    }))
                    history.push("/")
                }
            }
        })
    }, [])

    return (
        <div className="App">
            <Header />
            <Content props={{...props, status: status}}/>
            <Popouts props={props}/>
            <AlertBanner />
        </div>
    );
}

export default App;
