import firebaseData from "../../backend/firebase";
import {firebaseConfigVoron} from "../../configs/firebase-config";
import {Dispatch} from "react";
import {setHorses, setRequest, setVoron} from "../reducers";

export const loginVoron = async (dispatch: Dispatch<any>, servicesProp: any, pass: string) => {
    if (servicesProp) {
        let config = servicesProp
        if (config.length > 1) {
            config = servicesProp[1];
        } else {
            config = servicesProp[0];
        }

        config = config.split(',')

        config = {
            apiKey: config[0],
            authDomain: config[1],
            databaseURL: config[2],
            projectId: config[3],
            storageBucket: config[4],
            messagingSenderId: config[5],
            appId: config[6]
        };

        let horses = [];
        let personal = [];
        let request = []

        try {
            firebaseData.start(config, pass);

            firebaseData.get(firebaseConfigVoron.horsesDataVoron, (list) => {
                console.log(list);
                dispatch(setHorses({horses: list}));
            });
            firebaseData.get(firebaseConfigVoron.requestsDataVoron, (list) => {
                console.log(list);
                dispatch(setRequest({request: list}))
            });
            // request = firebaseData.get(firebaseConfigVoron.personalDataVoron);
            //
            // dispatch(setVoron({horses: horses, personal: personal, requests: request}));
        } catch (e) {
            firebaseData.restart(config, pass, () => {
                firebaseData.get(firebaseConfigVoron.horsesDataVoron, (list) => {
                    console.log(list);
                    dispatch(setHorses({horses: list}));
                });
                firebaseData.get(firebaseConfigVoron.requestsDataVoron, (list) => {
                    console.log(list);
                    dispatch(setRequest({request: list}))
                });
            })
        }

    } else {
        // if (servicesProp) {
        //     let config = servicesProp
        //
        //     if (config.length > 1) {
        //         config = servicesProp[1];
        //     } else {
        //         config = servicesProp[0];
        //     }
        //
        //     config = config.split(',')
        //
        //     config = {
        //         apiKey: config[0],
        //         authDomain: config[1],
        //         databaseURL: config[2],
        //         projectId: config[3],
        //         storageBucket: config[4],
        //         messagingSenderId: config[5],
        //         appId: config[6]
        //     };
        //     // firebaseData.restart(config, state.pass);
        // }
    }
}