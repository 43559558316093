import firebase from "firebase";

// @ts-ignore
const firebaseData = {
    start(config: object, pas: string) {
        firebase.initializeApp(config);
        firebase.auth().signInWithEmailAndPassword(localStorage.getItem('loginSave') || '', pas)
    },

    restart(config: object, pas: string, callback: undefined | (() => void) = undefined) {
        firebase.app()
        firebase.auth().signOut().then(r => {
            // @ts-ignore
            firebase.initializeApp(config, config.projectId);

            firebase.auth().signInWithEmailAndPassword(localStorage.getItem('loginSave') || '', pas)
            callback && callback()
            }
        )

    },

    get(path: string, calBack: undefined | ((list: object[]) => void) = undefined) {
        let listData: object[] = [];
        const commentsRef = firebase.database().ref(path);
        commentsRef.on('value', (data, id) => {
            const maxList = data.numChildren();
            let i = 0;
            data.forEach((data) => {
                const itemHorse = data.val();
                console.log(itemHorse)
                listData.push(itemHorse)
                i += 1
                if (maxList == i) {
                    calBack && calBack(listData)
                }
            })
        })

        return listData
    },

    update(path: string, key: string, id: string, string?: string) {
        // let url1 = path + key + '/' + id
        // firebase.database().ref().update({ : string})
    },

    push(patch: string, array: object, tr: () => void, fl: () => void) {
        firebase.database().ref(patch).push(array).then(() => {
                tr()
            }
        ).catch(() =>
            fl()
        )
    }

};

export default firebaseData;