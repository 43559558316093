import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
   request: false,
    horses: false,
    personal: false,
};

// export const getStatus = createAsyncThunk<object>(
//     'profile/me',
//     async (payload, { dispatch }) => {
//         try {
//             dispatch(setProfileStatus({ status: RequestState.LOADING }));
//             const profileResponse = await getProfileInfoApi();
//
//             if (profileResponse.status === 200) {
//                 dispatch(setProfileStatus({ status: RequestState.SUCCESS }));
//                 dispatch(
//                     setProfile({
//                         profile: profileResponse.data.result,
//                     }),
//                 );
//             }
//         } catch (err) {
//             dispatch(setProfileStatus({ status: RequestState.ERROR }));
//         }
//     },
// );

const voromSlice = createSlice({
    name: 'voron',
    initialState,
    reducers: {
        // setProfile: (state, action: PayloadAction<{ profile: Partial<IProfile> }>) => {
        //     state.profile = { ...state.profile, ...action.payload.profile };
        // },
        setVoron: (state, action: PayloadAction<{horses: any, requests: any, personal: any}>) => {
            state.horses = action.payload.horses;
            state.personal = action.payload.personal;
            state.request = action.payload.requests;
        },
        setHorses: (state, action: PayloadAction<{horses: any}>) => {
            state.horses = action.payload.horses;
        },
        setRequest: (state, action: PayloadAction<{request: any}>) => {
            state.request = action.payload.request;
        }
    },
});

export const { setVoron, setHorses, setRequest } = voromSlice.actions;

export default voromSlice.reducer;