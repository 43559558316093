import React from "react";
import {Body} from "./styled";

type ModalBodyType = {

}
const ModalBodyUI: React.FC<ModalBodyType> = ({children}) => {

    return (
        <Body onClick={(e) => {e.stopPropagation()}}>
            {children}
        </Body>
    )
};

export default ModalBodyUI;