import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {InitStateAlertType} from "../../types/types";

const initialState = false as InitStateAlertType;

// export const getStatus = createAsyncThunk<object>(
//     'profile/me',
//     async (payload, { dispatch }) => {
//         try {
//             dispatch(setProfileStatus({ status: RequestState.LOADING }));
//             const profileResponse = await getProfileInfoApi();
//
//             if (profileResponse.status === 200) {
//                 dispatch(setProfileStatus({ status: RequestState.SUCCESS }));
//                 dispatch(
//                     setProfile({
//                         profile: profileResponse.data.result,
//                     }),
//                 );
//             }
//         } catch (err) {
//             dispatch(setProfileStatus({ status: RequestState.ERROR }));
//         }
//     },
// );

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        // setProfile: (state, action: PayloadAction<{ profile: Partial<IProfile> }>) => {
        //     state.profile = { ...state.profile, ...action.payload.profile };
        // },
        //@ts-ignore
        setAlert: (state, action: PayloadAction<{ data: InitStateAlertType | boolean }>) => {
            state = action.payload.data
            return state
        }
    },
});

export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;