import {openAlertAction} from "./openCloseAlert";
import {setAssets} from "../reducers/vrchatReducer";
import {AssetDataUploadType} from "../../types/types";
import {URL_BACKEND} from "../../configs/url-config";

export const    loginVRchat = (dispatch: any) => {
    fetch(`${URL_BACKEND}/vrchat/assets`, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}})
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            dispatch(setAssets(data.result))
        })
        .catch(function (error) {
            console.warn(error)
            openAlertAction({dispatch: dispatch, text: error.toString()})
        })
}

export const addAsset = (data: AssetDataUploadType, succesC: () => void) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "__ddg1_=kdfAhpYEUWO8TSQRpDVt");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    //@ts-ignore
    fetch(`${URL_BACKEND}/vrchat/assets`, requestOptions)
        .then(response => response.text())
        .then(result => succesC())
        .catch(error => console.log('error', error));

    // const raw = JSON.stringify({
    //     "login": login,
    //     "password": password,
    //     "data": JSON.stringify(data),
    // });
    // console.log(raw)
    // fetch('https://request.denishik.ru/vrchat/assets', {mode: "cors", method: 'POST', body: raw, headers: {"Access-Control-Allow-Origin": "*"}})
    //     .then(function (response) {
    //         console.log(response)
    //         return response.json();
    //     })
    //     .then(function (data) {
    //         console.log('list>>', data)
    //     })
    //     .catch(function (error) {
    //         console.warn(error)
    //         // openAlertAction({dispatch: dispatch, text: error.toString()})
    //     })
}