import React from "react";

import style from './SwitchVideo.module.css';

const SwitchVideo = (props) => {

    return (
        <div className={style.body} onClick={props.onChange}>
            <span>{'Video banner'}</span>
            <div className={style.toggle} style={{justifyContent: props.status ? 'flex-end' : 'end'}}>
                <div className={props.status ? style.togButON : style.togButOff}/>
            </div>
        </div>
    )
};

export default SwitchVideo;