import {URL_BACKEND} from "../../../../../../../../../configs/url-config";
import {dataWorldType} from "./getWorlds";
import {getTime} from "../../../../../../../../../store/other/helperUtils";

export type dataUsersVrcatType = {
    id: string,
    isBlock: boolean,
    time?: string,
    type?: string,
    position?: string,
}

type Type = {
    successCallback: (data: dataUsersVrcatType[], total: number) => void;
    errorCallback: () => void;
    page?: number | string;
    reverce?: boolean;
}

export const getUserAppAction = (
    {
        successCallback,
        errorCallback,
        page = "all",
        reverce,
    }: Type
) => {
    fetch(`${URL_BACKEND}/vrcat/user/list?page=${page}${reverce ? "&reverse=true" : ""}`, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}})
        .then(function (response) {
            return response.json();
        })
        .then(function (res) {
            const tmp: dataUsersVrcatType[] = []
            const data = res.result.list;
            const total = res?.result?.total;
            data.map((data: any) => {
                tmp.push({
                    id: data.id,
                    isBlock: data.block == "1",
                    time: data.date == "None" ? "" : getTime(data.date),
                    position: data.position,
                    type: data?.type,
                })
            })
            successCallback(tmp, total)
        })
        .catch(function (error) {
            console.log(error)
            errorCallback();
        })
}