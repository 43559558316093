import React, {useCallback} from "react";

import style from './ServicePopout.module.css';
import {NavLink} from "react-router-dom";
import SwitchVideo from "./SwitchVideo/SwitchVideo";
import {InitStateServicesList} from "../../../types/types";
import {Body} from "./styled";
import {useDispatch} from "react-redux";
import {setServiceSelected} from "../../../store/reducers/otherReducer";
import {ScrollArea} from "../../../shared/ui/ScrollArea";

type ServicePopoutPropsType = {
    list: InitStateServicesList[]
    video: boolean;
    setVideo: () => void;
    service: number[] | boolean;
    close: () => void;
    st?: string | boolean;
}

const ServicePopout: React.FC<ServicePopoutPropsType> = (props) => {

    const dispatch = useDispatch();

    const handleService = useCallback((text) => {
        dispatch(setServiceSelected({service: text}));
        props.close();
    }, [])


    if (props.st === 'services') {
        return (
            <div>
                {props.service ? <Body>
                    {/*<ScrollArea type={"vertical"}>*/}
                        {typeof props.service !== 'boolean' && props.service.map((idService: number) => (
                            <NavLink onClick={() => handleService(props.list[idService - 1].text)} key={idService} activeClassName={'nav'} className={'nav'} to={props.list[idService - 1].url || ''}><h4 key={idService} >{props.list[idService - 1].text}</h4></NavLink>
                        ))}
                        <SwitchVideo status={props.video} onChange={props.setVideo} />
                        {/*<NavLink onClick={props.close} key={'logout'} activeClassName={'nav-logout'} className={'nav-logout'} to={'/logout'}><h4 className={'logout'} >{'Logout'}</h4></NavLink>*/}


                    {/*</ScrollArea>*/}
                   
                </Body> : ''}
            </div>
        )
    }else {
        return (<div />)
    }
};

export default ServicePopout;