import popoutsConfig from "../../configs/popouts-config";
import {setStatusPop} from "../reducers";
import {Dispatch} from "@reduxjs/toolkit";

export const setErrorPanel = (text: string, dispatch: Dispatch) => {

    if (text === 'ip') {

        dispatch(setStatusPop({data: {
            type: popoutsConfig.errorIP
        }}))
    } else {
        dispatch(setStatusPop({data: {
                type: popoutsConfig.error,
                text: text
            }}))
    }
}