import {setAlert} from "../reducers";
import {Options} from "react-lottie";

export const openAlertAction = ({dispatch, text = '', image = false, lottie = false}: {dispatch: any, text: string, image?: string | boolean, lottie?: boolean | Options}) => {
    function close() {
        dispatch(setAlert({data: false}));
    }

    dispatch(setAlert({
        data: {
            text: text,
            image: image ? image : false,
            lottie: lottie ? lottie : false,
        }}));
    setTimeout(close, 5000);
}

export const closeAlertAction = ({dispatch}: {dispatch: any}) => {
    dispatch(setAlert({data: false}));
}