import React from "react";
import { Body } from "./styled";
import ButtonMenu from "./ButtomItem";

type PropsI = {
    list: {title: string, url: string}[];
    buttonColor: string;
    menuColor: string;
}

const MenuBlockUI:React.FC<PropsI> = ({list, buttonColor,menuColor}) => {

    //left menu in horsebook view
    return (
        <Body menuColor={menuColor} listLen={list.length}>
            {list.map((data) => (<ButtonMenu colorSelected={menuColor} color={buttonColor} text={data.title} path={data.url}/>))}
        </Body>
    )
};

export default MenuBlockUI;