import style from "../../../VoronView/More/ContentBlock/ContentBlock.module.css";
import {Route} from "react-router-dom";
import React, {useEffect, useState} from "react";
import AssetsBlock from "./Blocks/AssetsBlock";
import WorldsBlock from "./Blocks/WorldsBlock";
import {dataWorldType, getWorldsAction} from "./Blocks/WorldsBlock/model/getWorlds";
import {PlusBlock} from "./Blocks/PlusBlock";
import {dataUserPlusType, getPlusUserAction} from "./Blocks/PlusBlock/model/getUsersPlus";
import {dataUsersVrcatType, getUserAppAction} from "./Blocks/WorldsBlock/model/getUsersApp";
import {dataUsersErrorsType, getUserErrorsAction} from "./Blocks/WorldsBlock/model/getError";
import ErrorsBlock from "./Blocks/ErrrosBlock";
import VRCatBlock from "./Blocks/VRCatBlock";

const ContentBlock = () => {
    const [worlds, setWorlds] = useState<dataWorldType[]>([]);
    const [plusUser, setPlusUser] = useState<dataUserPlusType[]>([]);
    const [appUsersPage, setAppUsersPage] = useState<number | string>(0);
    const [appUsersTotal, setAppUsersTotal] = useState<number>(20);
    const [appUsersReverce, setAppUsersReverce] = useState<boolean>(false);
    const [appUsers, setAppUsers] = useState<dataUsersVrcatType[]>([]);
    const [appErrors, setErrorsUsers] = useState<dataUsersErrorsType[]>([]);

    const loadList = () => {
        getUserAppAction({
            page: appUsersPage,
            reverce: appUsersReverce,
            successCallback: (appUsers, total) => {
                setAppUsers(prevState => [...prevState, ...appUsers]);
                setAppUsersTotal(total)
            },
            errorCallback: () => {}
        });
    }
    const handleReverce = () => {
        setAppUsersTotal(20);
        setAppUsers([]);
        setAppUsersReverce(prevState => !prevState);
        const isAll = appUsersPage === "all";
        setAppUsersPage(-1)
        setTimeout(() => {
            setAppUsersPage(isAll ? "all" : 0);
        }, 1000)

    }
    const handleAll = () => {
        setAppUsersTotal(20);
        setAppUsers([]);
        setAppUsersPage(-1);
        setTimeout(() => {
            setAppUsersPage(prevState => prevState === "all" ? 0 : "all");
        }, 1000)

    }

    useEffect(() => {
        if (appUsersPage != -1) {
            //@ts-ignore
            if ((appUsersPage + 1) * 20 <= appUsersTotal) {
                loadList();
            }
        }
    }, [appUsersPage])

    useEffect(() => {
        getWorldsAction({
            successCallback: words => setWorlds(words),
            errorCallback: () => setWorlds([])
        })
    }, [])
    useEffect(() => {
        getUserErrorsAction({
            successCallback: words => setErrorsUsers(words),
            errorCallback: () => setErrorsUsers([])
        })
    }, [])

    useEffect(() => {
        getPlusUserAction({
            successCallback: users => setPlusUser(users),
            errorCallback: () => setPlusUser([])
        })
    }, [])

    return (
        <div className={style.body}>
            <Route path={'/vrchat/assets'}><AssetsBlock/></Route>
            <Route path={'/vrchat/worlds'}><WorldsBlock worlds={worlds} /></Route>
            <Route path={'/vrchat/plus'}><PlusBlock isApp={false} users={plusUser} /></Route>
            <Route path={'/vrchat/users'}>
                <PlusBlock
                    isReverce={appUsersReverce}
                    onReverce={handleReverce}
                    /*//@ts-ignore*/
                    onPage={() => setAppUsersPage(prevState => prevState + 1)}
                    page={appUsersPage}
                    isApp={true}
                    onAll={handleAll}
                    usersApp={appUsers}
                />
            </Route>
            <Route path={'/vrchat/vrcat'}><VRCatBlock /></Route>
            <Route path={'/vrchat/errors'}><ErrorsBlock data={appErrors} /></Route>
        </div>
    )
};

export default ContentBlock;