import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assets: [],
    worlds: [],
};

// export const getStatus = createAsyncThunk<object>(
//     'profile/me',
//     async (payload, { dispatch }) => {
//         try {
//             dispatch(setProfileStatus({ status: RequestState.LOADING }));
//             const profileResponse = await getProfileInfoApi();
//
//             if (profileResponse.status === 200) {
//                 dispatch(setProfileStatus({ status: RequestState.SUCCESS }));
//                 dispatch(
//                     setProfile({
//                         profile: profileResponse.data.result,
//                     }),
//                 );
//             }
//         } catch (err) {
//             dispatch(setProfileStatus({ status: RequestState.ERROR }));
//         }
//     },
// );

const vrchatSlice = createSlice({
    name: 'vrchat',
    initialState,
    reducers: {
        // setProfile: (state, action: PayloadAction<{ profile: Partial<IProfile> }>) => {
        //     state.profile = { ...state.profile, ...action.payload.profile };
        // },
        setAssets: (state, action) => {
            state.assets = action.payload
        }
    },
});

export const { setAssets} = vrchatSlice.actions;

export default vrchatSlice.reducer;