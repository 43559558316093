import styled from "styled-components";

export const BodyList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  overflow-x: hidden;

  gap: 10px;
  padding: 10px;
  
  @media (max-width: 700px) {
    height: calc(100% - 70px);
  }
`;