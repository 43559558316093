import React from "react";
import InputAddStable from "../InputAddStable/InputAddStable";
import ButtonAddStable from "../ButtonAddStable/ButtonAddStable";

import style from './Slides.module.css';
import {SwitchUI} from "../../../../../../shared/ui/Switch";
import {ButtonUI} from "../../../../../../shared/ui/ButtonUI";
import {InputBlocks} from "../../../../../../shared/ui/InputUI/InputBlocks";
import {Body, EmptyBlock} from "./styled";

type PropsI = {
    des: string, setDes: (i: string) => void;
    name: string, setName: (i: string) => void;
    image: string, setImage: (i: string) => void;
    download: string, setDownload: (i: string) => void;
    price: number, setPrice: (i: number) => void;
    tags: string[], setTags: (i: string[]) => void;
    isMy: boolean, setIsMy: (i: boolean) => void;
    isFree: boolean, setIsFree: (i: boolean) => void;
    onNext: () => void;
    images: string[], setImages: (texts: string[]) => void;
}

const Slide1:React.FC<PropsI> = ({
    des,
    setDes,
    setName,
    name,
    setImage,
    image,
    download,
    setDownload,
    tags,
    setTags,
    price,
    setPrice,
    onNext,
    isMy,
    setIsMy,
    isFree,
    setIsFree,
    images,
    setImages,
}) => {

    return (<Body>
        <InputAddStable value={name} setValue={setName} hint={'Name asset'} />
        <InputAddStable value={des} setValue={setDes} hint={'Des'} paste={true}/>
        <InputAddStable value={image} setValue={setImage} hint={'Image'} paste={true} />
        <InputAddStable value={download} setValue={setDownload} hint={'Dowbload'} paste={true} />
        {!isFree && <InputAddStable type={"number"} value={price} setValue={setPrice} hint={'Price'}/>}
        <InputBlocks hint={"images"} defaultValue={images} onChange={setImages}  />
        {!isMy && <InputBlocks hint={"tags"} defaultValue={tags} onChange={setTags}  />}
        <SwitchUI title={'Мой ассет'} value={isMy} onChange={setIsMy} />
        <SwitchUI title={'Free'} value={isFree} onChange={setIsFree} />
        <ButtonUI bottom color={'#f984ff'} text={'next'} onClick={onNext} />
    </Body>)
};

export default Slide1;