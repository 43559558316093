import styled from "styled-components";

export const Body = styled.div `
  height: calc(100% - 20px);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 6px 0;
  border-radius: 20px;
`;

export const ErrorItemBody = styled.div`
  height: 100px;
  background: #B37FD9FF;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  padding: 5px;
  flex-direction: column;
  position: relative;

  .hover {
    opacity: 0;
    position: absolute;
    width: 85px;
    height: 40px;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #c99bc9;
    
    .delete-icon {
      padding: 5px;
    }
  }
  
  &:hover {
    .hover {
      opacity: 1;
    }  
  }

  .name {
    height: 40px;
  }

  .error {
    height: 60px;

    @media (max-width: 900px) {
      height: 100px;
    }
  }


  @media (max-width: 700px) {
    .hover {
      opacity: 1;
    }
    padding: 10px;
  }
`

export const BodyErrorList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  overflow-x: hidden;

  gap: 10px;
  padding: 10px;
  
  @media (max-width: 700px) {
    height: auto;
  }
`;