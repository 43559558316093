import ItemAsset from "../../../AssetsBlock/ItemAsset";
import React, {useCallback} from "react";
import style from "../../../../../../../VoronView/More/ContentBlock/Blocks/HorsesBlock/HorsesList/HorsesList.module.css";
import {dataWorldType} from "../../model/getWorlds";
import ItemWorld from "../ItemWorld";

type AssetsListType = {
    list: dataWorldType[],
    page: number;
}

// @ts-ignore
export const WorldsList:React.FC<AssetsListType> = ({list, page}) => {


    let listP = [];

    try {
        for (let i = 0; i < 4; i++) {
            listP.push(list[page * 4 + i]);
        }
    }catch (e) {
        listP = [];
    }

    const onEditWorld = useCallback((id: string) => {
        window.open(`https://vrchat.com/home/content/worlds/${id}/edit`, "_blank");
    }, [])

    return (
        <div className={style.body}>
            {list.map((data, index) => {
                return (
                    data ? <ItemWorld onEdit={() => onEditWorld(data.id)} fav={data.fav} name={data.title} onClick={() => {}} key={index} image={data.image} /> : ''
                )
            })}
        </div>
    )
};