import style from "../VoronView/VoronView.module.css";
import imagesConfig from "../../../../configs/images-config";
import ContentBlock from "./More/ContentBlock";
import MenuBlock from "./More/MenuBlock";
import React, {createRef, RefObject} from "react";
import {PropsType} from "../../../../types/types";
import {useSelector} from "react-redux";
import {selectOther} from "../../../../store/selectors";

interface propsI {
    props: PropsType
}

const VRChatView = ({props}:propsI) => {

    const other = useSelector(selectOther);
    const video = other.videoSit
    const videoBannerRef: RefObject<HTMLVideoElement> = createRef();

    return (
        <div className={style.body}>
            <img
                className={style.images}
                src={imagesConfig.bgVRChat}
                alt={'banner'}
            />
            {video ? <video
                // onLoadedMetadata={
                //     //@ts-ignore
                //     (event) => videoBannerRef.current?.style.visibility = 'visible'
                // }
                className={style.video}
                id={'videoHB'}
                ref={videoBannerRef}
                loop={true}
                autoPlay={true}
                muted={true}
                playsInline={true}
                poster={imagesConfig.bgVoron}
            >
                <source src={'https://v4x9gljxlqblzwzhinbuoq-on.drv.tw/Vkonyushne/my%20syte/assets/videos/bgVoron.mp4'}
                        type="video/mp4"/>
            </video> : ''}

            <div className={style.panel}>
                <ContentBlock />
                <MenuBlock />
            </div>
        </div>
    )
};

export default VRChatView;