import React, {useCallback} from "react";
import ErrorPopout from "./ErrorPopout/ErrorPopout";
import ServicePopout from "./ServicePopout/ServicePopout";
import AddStablePopout from "./HorseBookPopouts/AddStablePopout/AddStablePopout";
import ShowStablePopouts from "./HorseBookPopouts/ShowStablePopouts/ShowStablePopouts";
import popoutsConfig from "../../configs/popouts-config";
import storeConfig from "../../configs/store-config";
import AddShopPopouts from "./HorseBookPopouts/AddShopPopouts/AddShopPopouts";
import ShowShopPopout from "./HorseBookPopouts/ShowShopPopout/ShowShopPopout";
import {useDispatch, useSelector} from "react-redux";
import {selectOther, selectPopout, selectServicesData} from "../../store/selectors";
import {
    InitStatePopoutsType,
    InitStateServicesType,
    InitStateShop,
    StableDataType,
    StableDataUploadType
} from "../../types/types";

import style from './Popouts.module.css';
import {closeStatusPop, setAlert, setStatusPop} from "../../store/reducers";
import {textConfig} from "../../configs/text-config";
import lottieConfig from "../../configs/lottie-config";
import {openAlertAction} from "../../store/other/openCloseAlert";
import AddAssetsPopout from "./VRChatPopouts/AddAssetsPopout";
import {addAsset} from "../../store/other/loginVRchat";
import {setVideo} from "../../store/reducers/otherReducer";
import {addStableAction} from "./HorseBookPopouts/AddStablePopout/modal/addStableAction";
import {AddPlusUserPopouts} from "./VRChatPopouts/AddPlusUserPopouts";
import {ProfilePopout} from "./ProfilePopout";
import {ShowAssetsPopout} from "./VRChatPopouts/ShowAssetsPopout";

type PopoutsPropsType = {
    props: { dispatch: any; }
}

const Popouts: React.FC<PopoutsPropsType> = ({props}) => {

    const status: InitStatePopoutsType = useSelector(selectPopout);
    const other = useSelector(selectOther);
    const services: InitStateServicesType = useSelector(selectServicesData);
    const dispatch = useDispatch();

    //Узгаем надо ли показать попоут
    let servicesList = services.servicesList;
    let videoSitStatus = other.videoSit;

    const handleAlert = useCallback(() => {
        navigator.clipboard.writeText(String(other.ip)).catch(r => openAlertAction({
            dispatch: dispatch,
            text: textConfig.alertText.errorCopy,
        }));
        // }));
        openAlertAction({
            dispatch: dispatch,
            text: textConfig.alertText.copy,
            lottie: lottieConfig.copyConfig,
        })
    }, [dispatch, other.ip]);

    const addStable = useCallback(data => {
        addStableAction({
            action: {
                data: data,
            },
            suc: () => {
                dispatch(closeStatusPop());
                openAlertAction({
                    dispatch: dispatch,
                    text: 'Успешно добавленно',
                    lottie: lottieConfig.succesConfig
                })
            },
            err: err => {
                openAlertAction({
                    dispatch: dispatch,
                    text: err,
                    lottie: lottieConfig.errorConfig
                })
            }
        })
    }, []);

    return (
        <div>
            {status ? <div
                onClick={() => {
                    if (typeof status !== "boolean" && status?.type !== popoutsConfig.services) {
                        dispatch(closeStatusPop());
                    }
                }}
                className={style.body}
            >
                <ErrorPopout
                    st={typeof status !== "boolean" ? status?.type : false}
                    text={typeof status !== "boolean" ? status?.text : ''}
                    onCopy={handleAlert}

                />
                <ServicePopout
                    setVideo={() => dispatch(setVideo({
                        data: {video: !videoSitStatus}
                    }))}
                    video={videoSitStatus || true}
                    close={
                        () => dispatch(closeStatusPop())
                    }
                    st={typeof status !== "boolean" ? status?.type : false}
                    list={servicesList}
                    service={services?.services}
                />
                <AddStablePopout
                    st={typeof status !== "boolean" ? status?.type : false}
                    addStable={addStable}
                    alert={(text, image) => openAlertAction({
                        dispatch: dispatch,
                        text: text,
                        image: image,
                    })}
                />
                <ShowStablePopouts
                    st={typeof status !== "boolean" ? status?.type : false}
                    data={typeof status !== "boolean" ? status?.data : {}}
                    onChange={(data: StableDataType | boolean, key: string | undefined) => props.dispatch({
                        type: storeConfig.updateStableHB,
                        data: data,
                        key: key,
                    })}
                />
                <AddShopPopouts
                    st={typeof status !== "boolean" ? status?.type : false}
                    addShop={
                        (data: InitStateShop) => dispatch({
                            type: storeConfig.addShopHB,
                            data: data,
                        })
                    }
                    alert={handleAlert}
                />
                <ShowShopPopout
                    st={typeof status !== "boolean" ? status?.type : false}
                    data={typeof status !== 'boolean' ? status?.data : {}}
                    onChange={() => {
                    }}
                />

                <AddAssetsPopout
                    alert={(text, image) => openAlertAction({
                        dispatch: dispatch,
                        text: text,
                        image: image,
                    })}
                    st={typeof status !== "boolean" ? status?.type : false}
                    //@ts-ignore
                    addAsset={(data) => addAsset(data, () => dispatch(closeStatusPop()))}
                />

                <AddPlusUserPopouts
                    st={typeof status !== "boolean" ? status?.type : false}
                    close={() => dispatch(closeStatusPop())}
                />
                <ProfilePopout
                    st={typeof status !== "boolean" ? status?.type : false}
                    close={() => dispatch(closeStatusPop())}
                />
                <ShowAssetsPopout
                    st={typeof status !== "boolean" ? status?.type : false}
                    onClose={() => dispatch(closeStatusPop())}
                    asset={typeof status !== 'boolean' ? status?.data : {}}
                />

            </div> : ''}
        </div>

    )

};

export default Popouts;