import styled from "styled-components";

export const Body = styled.div`
  overflow-y: scroll;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  
  &::-webkit-scrollbar {
    width: 5px;/* ширина scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: white;
    /* цвет дорожки */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
    border: 5px solid white;  /* padding вокруг плашки */
  }
`;

export const ActionsBlock = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  
  .button {
    height: 17px;
  }
`

export const EmptyBlock = styled.div`
`;