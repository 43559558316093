import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {InitStatePopoutsType} from "../../types/types";

const initialState = false as InitStatePopoutsType;

const popoutSlice = createSlice({
    name: 'popout',
    initialState,
    reducers: {
        setStatusPop: (state, action: PayloadAction<{ data: InitStatePopoutsType }>) => (action.payload.data),
        closeStatusPop: (state) => initialState,
    },
});

export const { setStatusPop, closeStatusPop } = popoutSlice.actions;

export default popoutSlice.reducer;