import style
    from "../../../../../../VoronView/More/ContentBlock/Blocks/HorsesBlock/HorsesList/HorsesItem/HorseItem.module.css";
import React, {useState} from "react";

type ItemAssetType = {
    name: string,
    image: string,
    key: number,
    onClick: () => void,
}

const ItemAsset:React.FC<ItemAssetType> = (props) => {

    return (
        <div key={props.key} className={style.body} >
            <div
                key={props.key + '_card'}
                className={style.card}
                style={{backgroundImage: 'url(' + props.image + ')'}}
                onClick={props.onClick}
            >
                <div key={props.key + '_bg'} className={style.bg}>
                    <span key={props.key + '_span'}>{props.name}</span>
                </div>
            </div>
        </div>
    )
};

export default ItemAsset;