import React, {useState} from "react";
import style from "../../../../../VoronView/More/ContentBlock/Blocks/HorsesBlock/HorsesBlock.module.css";
import PageSwitch from "../../UI/PageSwitch";
import EmptyList from "../../UI/EmptyList/EmptyList";
import {dataWorldType} from "./model/getWorlds";
import {WorldsList} from "./ui/WorldsList";

const WorldsBlock: React.FC<{worlds: dataWorldType[]}> = ({worlds}) => {
    const [page, setPage] = useState(0);

    //узнаю максимум страниц
    const maxPage = worlds ? Math.floor(worlds.length / 4) : 0

    return (
        <div className={style.body}>
            <PageSwitch
                title={"Worlds"}
                page={page}
                setPage={setPage}
                maxPage={maxPage}
            />
            {worlds.length > 0 ? <WorldsList page={page} list={worlds}/> : <EmptyList onClick={() => {
            }}/>}
        </div>
    )
};

export default WorldsBlock;