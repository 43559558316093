import React, {useCallback} from 'react';
import { Body } from './styled';

type Types = {
  title?: string;
  value?: string;
  onChange?: (text: string) => void;
  placeholder?: string
}

export const InputBlock: React.FC<Types> = (
    {
        title,
        value,
        onChange,
        placeholder = title,
    }
) => {

  const onChnageValue = useCallback((e) => {
    onChange && onChange(e.target.value);
  }, [])

  return (
    <Body>
      <span className={'title'}>{title}</span>
      <input className={'input'} placeholder={placeholder} value={value} onChange={onChnageValue} />
    </Body>
  )
}