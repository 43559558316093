const imagesConfig = {
    edit: 'https://cdn-icons-png.flaticon.com/512/747/747994.png',
    done: 'https://cdn-icons-png.flaticon.com/512/64/64484.png',
    copy: "https://cdn-icons-png.flaticon.com/512/126/126498.png",
    empty: 'https://cdn-icons-png.flaticon.com/512/3388/3388419.png',
    del: 'https://cdn-icons-png.flaticon.com/512/812/812853.png',
    errorAlert: "https://cdn-icons-png.flaticon.com/512/929/929416.png",
    add: "https://1001freedownloads.s3.amazonaws.com/vector/thumb/98366/clarity-shutdown-icon.png",
    invisible: 'https://cdn-icons-png.flaticon.com/512/565/565655.png',
    emptyGradient: 'https://cdn-icons-png.flaticon.com/512/2039/2039126.png',
    powerGradient: "https://cdn-icons-png.flaticon.com/512/695/695550.png",
    sendCommandGradient: 'https://cdn-icons-png.flaticon.com/512/3820/3820259.png',
    phoneGradient: 'https://cdn-icons-png.flaticon.com/512/3781/3781619.png',
    bgLimboBot: 'https://catherineasquithgallery.com/uploads/posts/2021-02/1612740516_148-p-goluboi-fon-telegramm-190.jpg',
    bgHorseBook: 'https://lh3.googleusercontent.com/fife/AAWUweXXoBK7eW38jmdrjtfNUcDB7cc0jZU5ubF8c9J2WVHN94W59JvGmLAfZkbhUgoI-hGvf4JE5Ikx2pbsnLZrK8cNDKXjgWtNXjXyYS371wNJT9LC8jncM1lQPWTb27uwKrO_9MGAHGrqq01HyfsuqlrJhUxuW_BBDIm-WmLTqvom_aoWG4o40Ui-4lC95HZOhMFP4cpeEUi91P-RGZBUHxX6u6oo_DwqFBh7rfeUbz1I4APlwXDF5TrAHXevXO7rQLZ1IKZ_TGGb987QsbeuzoQLhTNC8P98Ji72Dq3EnDjW_ImMU_qbAYDrpJRBF2yWH0XDuIfzHqAKiHoVWxDq2sb40IxE59UAtAoSlO4nb-2kgDiS-XocyL3JbGuzWXoMGLYmNmj2b-C1Xnd1GZWolVgW6Yn32IAeW0hnVdUTEn7nPDg4HwVhXbLDWXC4OhhcFRyz7Lm4KP8ZNbzYERTeDSf4wcfP7aVhcX0__8AOd6VAqqJgPXH6tFJhlCs-2_LMdaGs1q7XSE9ONK5JPK0Aau4B6OvXkpJW9gt_kglNRKKECjrHk4VRzpYmeJNziIaX5ood_IYE1tscUXVn3bcL0NmiuGcqDL6r0GrSpOlc7ebzoT6vcZ7iqEufo6DPnjcGMKC7krvwwiHBMPjgwP7aW4QJrTlEe3XUS3XRBe4W9B09Op5g1MJHTYvSr6yLAnGIQVybxfAtZjxaPK6rwsqs3HTmNFvL89gZSQ=w1920-h1009-ft',
    bgVoron: 'https://firebasestorage.googleapis.com/v0/b/stables-stab3s.appspot.com/o/syte%2Fimages%2FIMG_0669.jpg?alt=media&token=c48a0618-96f6-4750-a7de-99874a4109a3',
    bgVRChat: 'https://vrchat.denishik.ru/static/media/worldsBG.d75753825047bca8a2e0.png',
    bgHorseVet: 'https://sun9-26.userapi.com/impg/QQDfGvsYrmsPB4f6GKJJCcn4RseAahL4K-9CwQ/vy8OmUHdebM.jpg?size=980x659&quality=95&sign=6f6b0fa078cb91faca9aa6a132eff574&type=album'
};

export default imagesConfig;