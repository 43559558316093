import {URL_BACKEND} from "../../../../../../../../../configs/url-config";
import {dataUserPlusType} from "./getUsersPlus";

type Type = {
    id: string;
    name: string;
    successCallback: () => void;
    errorCallback: () => void;
}

export const addPlusUserAction = (
    {
        id,
        name,
        successCallback,
        errorCallback,
    }: Type
) => {
    const data = {
        id: id,
        name: name
    };

    fetch(`${URL_BACKEND}/vrchat/plus`, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}, method: 'POST', body: JSON.stringify(data)})
        .then(function (response) {
            return response.json();
        })
        .then(function (res) {
            if (res.status == "success") {
                successCallback();
            } else {
                errorCallback();
            }
        })
        .catch(function (error) {
            errorCallback();
        })
}