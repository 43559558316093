import React, {useEffect} from 'react';
import MenuBlock from "./More/MenuBlock/MenuBlock";
import ContentBlock from "./More/ContentBlock/ContentBlock";

import style from './HorseBook.module.css';
import imagesConfig from "../../../../configs/images-config";
import {loginHB} from "../../../../store/other/loginHB";
import {useDispatch, useSelector} from "react-redux";
import {selectHB, selectOther, selectServicesData} from "../../../../store/selectors";

const HorseBookView = () => {

    const dispatch = useDispatch();
    const hb = useSelector(selectHB);
    const {servicesProp} = useSelector(selectServicesData);
    const {pass, videoSit} = useSelector(selectOther);

    useEffect(() => {
        if (hb === false && !pass) {
            console.log(pass)
            loginHB(dispatch, servicesProp[0], pass);
        }
    }, [dispatch, hb, pass, servicesProp])

    return(
        <div className={style.body}>
            <img
                class={style.imgBanner}
                src={imagesConfig.bgHorseBook}
                alt={'banner'}
            />
            {videoSit ? <video
                onLoadedMetadata={
                    (event) => document.getElementById('videoHB').style.visibility = 'visible'
                } className={style.video}
                id={'videoHB'}
                loop="loop"
                autoPlay='autoPlay'
                muted="muted"
                playsInline='playsInline'
                poster={'https://lh3.googleusercontent.com/fife/AAWUweXXoBK7eW38jmdrjtfNUcDB7cc0jZU5ubF8c9J2WVHN94W59JvGmLAfZkbhUgoI-hGvf4JE5Ikx2pbsnLZrK8cNDKXjgWtNXjXyYS371wNJT9LC8jncM1lQPWTb27uwKrO_9MGAHGrqq01HyfsuqlrJhUxuW_BBDIm-WmLTqvom_aoWG4o40Ui-4lC95HZOhMFP4cpeEUi91P-RGZBUHxX6u6oo_DwqFBh7rfeUbz1I4APlwXDF5TrAHXevXO7rQLZ1IKZ_TGGb987QsbeuzoQLhTNC8P98Ji72Dq3EnDjW_ImMU_qbAYDrpJRBF2yWH0XDuIfzHqAKiHoVWxDq2sb40IxE59UAtAoSlO4nb-2kgDiS-XocyL3JbGuzWXoMGLYmNmj2b-C1Xnd1GZWolVgW6Yn32IAeW0hnVdUTEn7nPDg4HwVhXbLDWXC4OhhcFRyz7Lm4KP8ZNbzYERTeDSf4wcfP7aVhcX0__8AOd6VAqqJgPXH6tFJhlCs-2_LMdaGs1q7XSE9ONK5JPK0Aau4B6OvXkpJW9gt_kglNRKKECjrHk4VRzpYmeJNziIaX5ood_IYE1tscUXVn3bcL0NmiuGcqDL6r0GrSpOlc7ebzoT6vcZ7iqEufo6DPnjcGMKC7krvwwiHBMPjgwP7aW4QJrTlEe3XUS3XRBe4W9B09Op5g1MJHTYvSr6yLAnGIQVybxfAtZjxaPK6rwsqs3HTmNFvL89gZSQ=w1920-h1009-ft'}
            >
                <source src={'https://prod-app7683801-0bd79ff4fe59.pages-ac.vk-apps.com/static/media/intro1.9fd997ef.mp4'} type="video/mp4"/>
            </video> : ''}
            <div className={style.panel}>
                <ContentBlock />
                <MenuBlock  />
            </div>
        </div>
    )
};

export default HorseBookView;