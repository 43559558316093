const popoutsConfig = {
    hbShowStable: "hbShowStable",
    hbShowShop: 'hbShowShop',
    hbAddStable: 'hbAddStable',
    hbAddShop: 'hbAddShop',
    vrchatAddPlus: 'vrchatAddPlus',
    vrchatAddAsset: 'vrchatAddAsset',
    vrchatShowAsset: 'vrchatShowAsset',
    services: 'services',
    errorIP: 'errorIP',
    error: 'error',
};

export default popoutsConfig