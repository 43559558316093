import React, {useState} from "react";
import Slide1 from "./More/Slides/Slide1";
import Slide2 from "./More/Slides/Slide2";

import style from './AddStablePopout.module.css';
import {textConfig} from "../../../../configs/text-config";
import imagesConfig from "../../../../configs/images-config";
import { AssetDataUploadType } from "../../../../types/types";
import {useSelector} from "react-redux";
import {selectVRChat} from "../../../../store/selectors";
import popoutsConfig from "../../../../configs/popouts-config";
import ModalBodyUI from "../../../../shared/ui/ModalBodyUI";

type AddStablePopoutPropsType = {
    alert: (text: string, image: string) => void;
    addAsset: (data: AssetDataUploadType ) => void;
    st?: string | boolean;
}

const AddAssetsPopout: React.FC<AddStablePopoutPropsType> = (props) => {

    const { assets } = useSelector((selectVRChat));

    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [des, setDes] = useState("");
    const [download, setDownload] = useState("");
    const [price, setPrice] = useState<number>(0);
    const [images, setImages] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [slide, setSlide] = useState(0);
    const [isMy, setIsMy] = useState(false);
    const [isFree, setIsFree] = useState(false);

    function nextSlide() {
        if (name && image &&  download && des ) {
            if (slide === 0 )
                setSlide(slide + 1)
            else
                prepAddAsset();

        }else {
            props.alert(textConfig.popoutsText.shopErrorEmpty, imagesConfig.empty);
        }
    }

    function prepAddAsset() {
        let arrayStable : AssetDataUploadType  = {
            name: name,
            des: des,
            image: image,
            images: images,
            id: assets.length + 1,
            download: download,
            tags: isMy ? ["my"] : tags,
            price: isFree ? 0 : price,
        };

        props.addAsset(arrayStable);
    }

    if (props.st === popoutsConfig.vrchatAddAsset) {
        return (
            <ModalBodyUI>
                {slide ? <Slide2
                    tags={tags}
                    image={image}
                    price={price}
                    name={name}
                    des={des}
                    download={download}
                    onBack={() => setSlide(0)}
                    onNext={nextSlide}
                    isMy={isMy}
                    images={[image, ...images]}
                /> : <Slide1
                    tags={tags} setTags={setTags}
                    name={name} setName={setName}
                    des={des} setDes={setDes}
                    image={image} setImage={setImage}
                    download={download} setDownload={setDownload}
                    onNext={nextSlide}
                    isMy={isMy} setIsMy={setIsMy}
                    images={images}
                    price={price}
                    setPrice={setPrice}
                    setImages={setImages}
                    isFree={isFree}
                    setIsFree={setIsFree}
                />}

            </ModalBodyUI>
        );
    }else {
        return (<div />);
    }
};

export default AddAssetsPopout;