import React from 'react';

import style from './ListCitys.module.css';
import CityItem from "./CityItem/CityItem";

const ListCitys = (props) => {

    return (
        <div className={style.body}>
            {props.list.map((text, index) => (
                <CityItem key={index} sel={index === props.sel} onClick={() => props.setSel(index)} text={text} />
            ))}
        </div>
    )
};

export default ListCitys