import {ServicesSelectedType} from "../../../types/types";
import logoHorseVET from '../../../shared/media/images/horseVET_logo.png';

export const getLogoHeader = (nameService: ServicesSelectedType) => {
    switch (nameService) {
        case "Voron":
            return "https://voron.denishik.ru/favicon.ico";
        case "Horse Book":
           return "https://firebasestorage.googleapis.com/v0/b/hshn-301a5.appspot.com/o/other%2Fзагруженное%20(2).png?alt=media&token=d9363400-66ff-495e-902e-effcf80861cb";
        case "VRChat":
            return "https://images.squarespace-cdn.com/content/v1/5f0770791aaf57311515b23d/1594323097093-T0ZKL126WLLITS38XH7L/logo.png";
        case "HorseVet":
            return logoHorseVET;
        case "LimboBot":
            return "'https://lh3.googleusercontent.com/fife/AAWUweWITl68-8fzbGvs0CiTO1Xivb_fvczF2xY0QoXkX0qKBRmaw0X4UQ9qRUpCFVn392kpAxEgwKW2yCUdNN9ILoX3jp6RL6GhhpgEhoR6BTfJQpsfz-Ta5kqRwuQ_5A54P96bE7yda_O8Pg4jqMogi8D2H-SosWYoZtXF5XfT6uBF1jwohZJTvMf2o3fRxAd2gk_odiUA15apMeeVVObY4eBx1vFMdLZXZq0UiHJTuchIY8jq4nxmSjRUCvyoCuRGLc0nyzdZEkYKb6jj_quqFKU_wGkGZ9k2i6Vs2lTB8wn0hohe0Ljl5bbUtF8YLFHHs3QYSEaB3hTyg-8naE5tui4yARXKEjxEmtXqrAWcMl4kGIA1l2ebjzERp1LjtManTbx-ug2p_4cFPLOb7IZtyQ78t5wH8TvgksYitL6Ni2gvCpHjLTlyWpzDo0qyzkaJIVbFnOpsIuIkhNlrSlhrnY-D-7x7yk_A1mciNN_85ZSPXXvLrKJA6XHxKkInKA9zsCFdYwd9P_2Fptk4VsilHc-1ve7p5JeDnV3ph6DlmxnlZHryN_aeDBTEZKNPLNV_Vewhnoe1XvUDS3W5vNCtR_dBqdryrrKV3OOTaJGn9ZD6UYWk_s0ux_RZBkaLfilKdGjWhTEYv842GO4B0sO1rd191gumGPbKPU8fra5KLYoD3qNXDqUvTxmmIf3cGCnMj1GKQkmy_Xk6aQpIf7m7yn-t18_A6WvPEGs=w1650-h907-ft'";
        case "":
            return 'https://lh3.googleusercontent.com/fife/AAWUweWITl68-8fzbGvs0CiTO1Xivb_fvczF2xY0QoXkX0qKBRmaw0X4UQ9qRUpCFVn392kpAxEgwKW2yCUdNN9ILoX3jp6RL6GhhpgEhoR6BTfJQpsfz-Ta5kqRwuQ_5A54P96bE7yda_O8Pg4jqMogi8D2H-SosWYoZtXF5XfT6uBF1jwohZJTvMf2o3fRxAd2gk_odiUA15apMeeVVObY4eBx1vFMdLZXZq0UiHJTuchIY8jq4nxmSjRUCvyoCuRGLc0nyzdZEkYKb6jj_quqFKU_wGkGZ9k2i6Vs2lTB8wn0hohe0Ljl5bbUtF8YLFHHs3QYSEaB3hTyg-8naE5tui4yARXKEjxEmtXqrAWcMl4kGIA1l2ebjzERp1LjtManTbx-ug2p_4cFPLOb7IZtyQ78t5wH8TvgksYitL6Ni2gvCpHjLTlyWpzDo0qyzkaJIVbFnOpsIuIkhNlrSlhrnY-D-7x7yk_A1mciNN_85ZSPXXvLrKJA6XHxKkInKA9zsCFdYwd9P_2Fptk4VsilHc-1ve7p5JeDnV3ph6DlmxnlZHryN_aeDBTEZKNPLNV_Vewhnoe1XvUDS3W5vNCtR_dBqdryrrKV3OOTaJGn9ZD6UYWk_s0ux_RZBkaLfilKdGjWhTEYv842GO4B0sO1rd191gumGPbKPU8fra5KLYoD3qNXDqUvTxmmIf3cGCnMj1GKQkmy_Xk6aQpIf7m7yn-t18_A6WvPEGs=w1650-h907-ft'
    }
}