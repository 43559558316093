import React from "react";

import {NavLink} from "react-router-dom";
import {BodyButton} from "./styled";

type PropsI = {
    path: string,
    text: string,
    color: string,
    colorSelected: string,
}
const ButtonMenu:React.FC<PropsI> = ({
    path,
    text,
    color,
    colorSelected
                    }) => {

    return (
        <BodyButton color={color} colorSelected={colorSelected}>
            <NavLink className={"nav"} activeClassName={"navActiv"} to={path}>
                <h4>{text}</h4>
            </NavLink>
        </BodyButton>
    )
};

export default ButtonMenu;