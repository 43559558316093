const textConfig = {
    popoutsText: {
        shopInputName: "name shop",
        shopInputPhone: "phone",
        shopInputWeb: "web url",
        shopInputEmail: "email",
        shopInputPhoto: "photo",
        shopButtonNext: 'next',
        shopButtonsBack: "back",
        shopErrorEmpty: 'Empty in input',
        denyIp: 'this ip not registered',
    },

    buttonsText: {
        copyIp: 'Copy ip'
    },

    alertText: {
        errorCopy: 'Error copy',
        copy: 'Copied id',
    },

    voronText: {
        requestListItemTarnning: 'Tranning: ',
    }
}

export {textConfig}