import React from 'react';
import style from "../../../VoronView/More/ContentBlock/ContentBlock.module.css";
import {Redirect, Route} from "react-router-dom";
import EmptyBlock from "../../../VoronView/More/ContentBlock/Blocks/EmptyBlock/EmptyBlock";
import HorsesBlock from "../../../VoronView/More/ContentBlock/Blocks/HorsesBlock/HorsesBlock";
import RequestBlock from "../../../VoronView/More/ContentBlock/Blocks/RequestsBlock/RequestBlock";

type Types = {

}

export const ContentBlock: React.FC<Types> = () => {

  return (
      <div className={style.body}>
          <Route exact path={'/horsevet'}><EmptyBlock /></Route>
          <Redirect to={'/horsevet'} />
      </div>
  )
}