import React from "react";

import style from './ShopItem.module.css';

const ShopItem = (props) => {

    return (
        <div key={props.key} className={style.body} >
            <div
                key={props.key + '_card'}
                className={style.card}
                style={{backgroundImage: 'url(' + props.image + ')'}}
                onClick={props.onClick}
            >
                <div key={props.key + '_bg'} className={style.bg}>
                    <span key={props.key + '_span'}>{props.name}</span>
                </div>
            </div>
        </div>
    )
};

export default ShopItem;