import {URL_BACKEND} from "../../../../../../../../../configs/url-config";

type Type = {
    successCallback: (data: dataUserPlusType[]) => void;
    errorCallback: () => void;
}

export type dataUserPlusType = {
    id: string,
    name: string
}
export const getPlusUserAction = (
    {
        successCallback,
        errorCallback,
    }: Type
) => {
    fetch(`${URL_BACKEND}/vrchat/plus`, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}})
        .then(function (response) {
            return response.json();
        })
        .then(function (res) {
            const tmp: dataUserPlusType[] = []
            const data = res.result;
            console.log(data)
            data.list.map((data: any) => {
                tmp.push({
                    id: data.id,
                    name: data.name,
                })
            })
            successCallback(tmp)
        })
        .catch(function (error) {
            errorCallback();
        })
}