import React from 'react';
import { AvatarBody } from './styled';

type Types = {
  src: string;
  size?: string | number;
  className?: string;
}

export const AvatarUI: React.FC<Types> = ({
    size = 30,
    src= "",
    className,
                                          }) => {

  return (
    <AvatarBody size={size} src={src} className={className} />
  )
}