import React from 'react';
import {Body} from "./styled";
// @ts-ignore
import addIco from "../../../../../../../../../../shared/media/images/add.ico";
import delIco from "../../../../../../../../../../shared/media/images/delete.png";

type Types = {
    name?: string;
    id?: string;
    onDelete?: () => void;
    onClick?: () => void;
    isAdd?: boolean;
    isLoad?: boolean;
    isHover?: boolean;
    isBlock?: boolean;
}

export const ItemUserPlus: React.FC<Types> = (
    {
        isHover = true,
        onClick,
        onDelete,
        name,
        id,
        isAdd,
        isLoad,
        isBlock,
    }
) => {

    return (
        <Body onClick={onClick} isBlock={isBlock}>
            {(!isAdd && !isLoad) ? (<>
                <span className={'title'}>{name}</span>
                <span className={'id'}>{id}</span>
                {isHover && <div className={'hover-actions'}>
                    <img alt={'delete-action'} className={'icon'} src={delIco}/>
                </div>}
            </>) : (
                isAdd ? <div className={'add-block'}>
                    <span className={'add-text'}>Добавить пользователя</span>
                    <img alt={'add-icon'} className={'add-icon'} src={addIco}/>
                </div> : <div className={'add-block'}>
                    <span className={'add-text'}>Загрузить еще...</span>
                    <img alt={'add-icon'} className={'add-icon'} src={addIco}/>
                </div>

            )}

        </Body>
    )
}