import React, {useEffect, useState} from "react";

import style from './LimboBotView.module.css';
import TerminalBlock from "./More/TerminalBlock/TerminalBlock";
import InputBlock from "./More/InputBlock/InputBlock";
import imagesConfig from "../../../../configs/images-config";

const LimboBotView = ({props}) => {

    //Проверка на полученых данных,если нету, то редирект для получения
    // if (!props.dispatch({type: storeConfig.getSevices}) || props.dispatch({type: storeConfig.getSevices}).indexOf('1') === -1) {
    //     window.location.href = urlConfig.root;
    // }

    const [textTerminal] = useState(' ');

    useEffect(() => {
        // props.dispatch({type: storeConfig.getLimboBotStatus});
    }, [props])

    function onClickSwitch() {
        // props.dispatch({type: storeConfig.switchLimboBotStatus});
    }

    return (
        <div className={style.body}>
            <img
                className={style.imageBanner}
                src={imagesConfig.bgLimboBot}
                alt={'banner'}
            />
            <div className={style.panel}>
                <TerminalBlock text={textTerminal} />
                <InputBlock onClick={onClickSwitch} />
            </div>
        </div>
    )
};

export default LimboBotView;