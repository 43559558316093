import React from "react";
import {Redirect, Route} from "react-router-dom";
import EmptyBlock from "./Blocks/EmptyBlock/EmptyBlock";
import StableBlock from "./Blocks/StableBlock/StableBlock";

import style from './ContentBlock.module.css';
import ShopsBlock from "./Blocks/ShopsBlock/ShopsBlock";

const ContentBlock = () => {

    return (
        <div className={style.body}>
            <Route exact path={'/horsebook'}><EmptyBlock /></Route>
            <Route path={'/horsebook/stables'} ><StableBlock /></Route>
            <Route path={'/horsebook/shops'} ><ShopsBlock /></Route>
            <Redirect to={'/horsebook/stables'} />
        </div>
    )
};

export default ContentBlock;