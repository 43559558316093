import React from "react";
import storeConfig from "../../../../../../../../configs/store-config";
import RequestsList from "./RequestsList/RequestsList";
import EmptyRequest from "./RequestsEmpty/EmptyRequest";

import style from './RequestsBlock.module.css';
import {useSelector} from "react-redux";
import {selectVoron} from "../../../../../../../../store/selectors";

const RequestBlock = (props) => {

    const data = useSelector(selectVoron);

    return (
        <div className={style.body}>
            {data.request.length > 0 ?
                <RequestsList adapter={data.request} /> :
                <EmptyRequest onClick={() => props.dispatch({type: storeConfig.updateDisplay})} />
            }
        </div>
    )
};

export default RequestBlock;