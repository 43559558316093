import React from "react";

import style from './ButtonAddShop.module.css'

const ButtonAddShop = (props) => {

    return (
        <div className={style.body}>
            <div className={style.button} onClick={props.onClick}>
                <h4>{props.text}</h4>
            </div>
        </div>
    )
};

export default ButtonAddShop;