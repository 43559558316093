import styled from "styled-components";

export const Body = styled.div<{ disabled?: boolean }> `
  width: 80%;
  border-radius: 20px;
  padding: 8px;
  background: ${props => props.disabled ? "rgba(199,180,222,0.8)" : "#B37FD9FF"};
  display: flex;
  cursor: ${props => props.disabled ? "cursor" : "pointer"};
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0 auto;
`;