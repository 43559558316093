import React from "react";
import ImagesAddStable from "../ImagesAddStable/ImagesAddStable";
import ButtonAddStable from "../ButtonAddStable/ButtonAddStable";
import TextAddStable from "../TextAddStable/TextAddStable";

import style from './Slides.module.css';
import {ActionsBlock, Body} from "./styled";
import {ButtonUI} from "../../../../../../shared/ui/ButtonUI";

type TypesI = {
    tags: string[];
    image: string;
    download: string;
    name: string;
    des: string;
    images: string[];
    price: number;
    onBack?: () => void;
    onNext: () => void;
    isMy: boolean;
}

const Slide2:React.FC<TypesI> = (
    {
        tags,
        des,
        name,
        onBack,
        onNext,
        image,
        price,
        download,
        images,
        isMy
    }
) => {

    let tagData: string[] = []
    try {
        tagData = tags;
    } catch (e) {

    }

    return (
        <Body>
            <ImagesAddStable
                pic1={images[0]}
                pic2={images[1]}
            />
            <TextAddStable title={true} text={name} />
            <TextAddStable text={'des: ' + des} />
            <TextAddStable text={'price: ' + (price > 0 ? price : "Free") } />
            <TextAddStable
                url={download}
                text={ 'Фаил загрузки'}
            />
            <div>
                {isMy
                    ? <TextAddStable title={'Мой ассет'} />
                    : tagData.map((text) => (<TextAddStable title={text} />))}
            </div>
            <ActionsBlock>
                {onBack && <ButtonUI className={"button"} color={'#f984ff'} xl={true} text={'< Back'} onClick={onBack}/>}
                <ButtonUI className={"button"} color={'#f984ff'} xl={true} text={onBack ? 'Add asset >' : "Close"} onClick={onNext} />
            </ActionsBlock>
        </Body>
    )
};

export default Slide2