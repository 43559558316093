import React from "react";

import style from './ItemStable.module.css';

const ItemStable = (props) => {

    return (
        <div className={style.body} onClick={props.onClick}>
            <div className={style.card} style={{backgroundImage: 'url(' + props.image + ')'}}>
                <div className={style.bg}>
                    <span>{props.name}</span>
                </div>
            </div>
        </div>
    )
};

export default ItemStable;