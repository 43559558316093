import {URL_BACKEND} from "../../../../../../../../../configs/url-config";

type Type = {
    successCallback: (data: dataWorldType[]) => void;
    errorCallback: () => void;
}

export type dataWorldType = {
    id: string,
    title: string,
    image: string,
    tag: string[],
    fav: string,
}

export const getWorldsAction = (
    {
        successCallback,
        errorCallback,
    }: Type
) => {
    fetch(`${URL_BACKEND}/vrchat/author`, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}})
        .then(function (response) {
            return response.json();
        })
        .then(function (res) {
            const tmp: dataWorldType[] = []
            const data = JSON.parse(res.result.replaceAll("'", '"'));
            data.list.map((data: any) => {
                tmp.push({
                    id: data.id,
                    title: data.name,
                    image: data.image,
                    tag: data.tags,
                    fav: data.favorites,
                })
            })
            successCallback(tmp)
        })
        .catch(function (error) {
            errorCallback();
        })
}