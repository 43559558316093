import React, {useCallback, useEffect, useState} from "react";
import ImageShowStable from "./ImagesShowStable/ImageShowStable";

import style from './ShowStablePopouts.module.css';
import TextShowStable from "./TextShowStable/TextShowStable";
import popoutsConfig from "../../../../configs/popouts-config";
import {StableDataType} from "../../../../types/types";

type ShowStablePopoutsPropsType = {
    data: any;
    onChange: (data: StableDataType | boolean, key: string | undefined) => void;
    st?: boolean | string;
}

const ShowStablePopouts: React.FC<ShowStablePopoutsPropsType> = (props) => {

    const [data, setData] = useState<StableDataType | boolean>(false);

    const onChangeText = useCallback(() => {
        // if (typeof data !== "boolean") {
        //     props.onChange(data, data.key);
        // }
    }, [])

    const prepInfo = useCallback(() => {
        try {
            return {
                key: props.data.key,
                name: props.data.name,
                des: props.data.des ? props.data.des : 'empty',
                pic1: props.data.pic1 ? props.data.pic1 : false,
                pic2: props.data.pic2 ? props.data.pic2 : false,
                pic3: props.data.pic3 ? props.data.pic3 : false,
                note: props.data.note ? props.data.note : 'not specified',
                logo: props.data.logo ? props.data.logo : false,
                map: props.data.map1 ? props.data.map1 + ',' + props.data.map1 : 'not specified',
                syte: props.data.syte ? props.data.syte : '#',
                tel: props.data.tel ? props.data.tel : '#',
                email: props.data.email ? props.data.email : '#',
            }

        }catch (e) {
            console.warn('ShowStablePopouts.js> prepInfo() error in preparation')
            return false;
        }
    }, [props.data]);

    useEffect(() => {
        setData(prepInfo() || {} as StableDataType)
    }, [prepInfo])

    if (props.st === popoutsConfig.hbShowStable) {

        return typeof data !== 'boolean' ? (
            <div className={style.body} onClick={(e) => {e.stopPropagation()}}>
                <ImageShowStable
                    pic1={data ? data.pic1 : ""}
                    pic2={data ? data.pic2 : ""}
                    pic3={data ? data.pic3 : ""}
                />
                <TextShowStable
                    title={true}
                    text={data.name}
                    onChange={(text : string) => {
                        data.name = text
                        onChangeText()
                }}
                />
                <TextShowStable
                    text={'des: ' + data.des}
                    onChange={(text: string) => {
                        data.des = text
                        onChangeText()
                }}
                />
                <TextShowStable
                    text={'note: ' + data.note}
                    onChange={(text: string) => {
                        data.note = text
                        onChangeText()
                }}
                />
                <TextShowStable
                    text={'tel: ' + data.tel + '  email:' + data.email}
                    onChange={() => {
                        // data.name = text
                        // onChangeText()
                    }}
                />

            </div>
        ) : (
            <div />
        )
    }else {
        return (<div />);
    }

};

export default ShowStablePopouts;