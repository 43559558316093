import ItemAsset from "../ItemAsset";
import React, {useEffect, useState} from "react";
import style from "../../../../../../VoronView/More/ContentBlock/Blocks/HorsesBlock/HorsesList/HorsesList.module.css";
import {setStatusPop} from "../../../../../../../../../store/reducers";
import popoutsConfig from "../../../../../../../../../configs/popouts-config";
import {useDispatch} from "react-redux";
import {AssetDataUploadType} from "../../../../../../../../../types/types";
import {getTagsBack} from "../../../../../../../../../shared/utils/tagsUtils";

type AssetsListType = {
    list: Array<string[]>
    page: number;
}

// @ts-ignore
export const AssetsList: React.FC<AssetsListType> = ({list, page}) => {

    const [data, setData] = useState([[]]);
    const dispatch = useDispatch();

    useEffect(() => {
        const listP = []
        for (let i = 0; i < 4; i++) {
            listP.push(list[page * 4 + i]);
        }
        // @ts-ignore
        setData(listP)
    }, [page])


    return (
        <div className={style.body}>
            {data.map((data, index) => {
                return (
                    data ? <ItemAsset
                        name={data[1]}
                        onClick={() => dispatch(setStatusPop({data:{
                                type: popoutsConfig.vrchatShowAsset,
                                data: {
                                    name: data[1],
                                    image: data[7],
                                    des: data[2],
                                    tags: getTagsBack(data[5]),
                                    download: data[4],
                                    images: [data[7], data[3]]
                                } as unknown as AssetDataUploadType,
                            }}))}
                        key={index}
                        image={data[3]}/> : ''
                )
            })}
        </div>
    )
};