import styled from "styled-components";

export const Body = styled.div `
  height: calc(100% - 20px);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 6px 0;
  border-radius: 20px;
  
  .actions-block-app {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    padding: 5px;

    .outline {
      background: transparent;
      border: 1px solid #f984ff;
      color: #f984ff;
    }
    .no-outline {
      background: #f984ff;
      border: 1px solid transparent;
      color: white;
    }
  }
`;