import React from 'react';

import style from './ButtonItem.module.css';

const CityItem = (props) => {

    return (
        <div id={props.key} onClick={() => {props.onClick()}} key={props.key} className={style.body}>
            <span key={props.key + '_span'}>{props.text}</span>
        </div>
    )
};

export default CityItem;