import React from "react";

import style from './TextAddShop.module.css';

const TextAddShop = (props) => {

    return (
        <div className={style.body}>
            {props.title ?
                props.url ?
                    <a href={props.url}>{props.text}</a> :
                    <span>{props.text}</span>

            :
                <h4>{props.text}</h4>
            }
        </div>
    )
};

export default TextAddShop;