import React, {useEffect} from "react";

import style from './VoronView.module.css';
import MenuBlock from "./More/MenuBlock/MenuBlock";
import ContentBlock from "./More/ContentBlock/ContentBlock";
import imagesConfig from "../../../../configs/images-config";
import {useDispatch, useSelector} from "react-redux";
import {selectOther, selectServicesData, selectVoron} from "../../../../store/selectors";
import {loginVoron} from "../../../../store/other/loginVoron";

const VoronView = ({props}) => {
    //get videobanner status (show video or no)
    const {videoSit, pass} = useSelector(selectOther);
    const {servicesProp} = useSelector(selectServicesData);
    const data = useSelector(selectVoron);
    const dispatch = useDispatch();

    useEffect(() => {
        if (data.horses === false && !pass) {
            loginVoron(dispatch, servicesProp, pass);
        }
    }, [data, pass, servicesProp])

    return (
        <div className={style.body}>
            <img
                className={style.images}
                src={imagesConfig.bgVoron}
                alt={'banner'}
            />
            {videoSit ? <video
                onLoadedMetadata={
                    (event) => document.getElementById('videoHB').style.visibility = 'visible'
                } className={style.video}
                id={'videoHB'}
                loop="loop"
                autoPlay='autoPlay'
                muted="muted"
                playsInline='playsInline'
                poster={imagesConfig.bgVoron}
            >
                <source src={'https://v4x9gljxlqblzwzhinbuoq-on.drv.tw/Vkonyushne/my%20syte/assets/videos/bgVoron.mp4'}
                        type="video/mp4"/>
            </video> : ''}

            <div className={style.panel}>
                <ContentBlock dispatch={props.dispatch} />
                <MenuBlock />
            </div>
        </div>
    )
};

export default VoronView