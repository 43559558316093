import tokens from "../tokens";
import {setErrorPanel} from "./setError";

import {setAccaunt, setIp, setOther, setUrlSave} from "../reducers/otherReducer";
import {setStatusPop} from "../reducers";
import {setServicesData, setServicesID} from "../reducers/servicesDataReducers";
import {Dispatch} from "@reduxjs/toolkit";
import {InitStateOtherType, InitStatePopoutsType, InitStateServicesType} from "../../types/types";
import {setMe} from "../reducers/meReducer";

type actionType = {
    login: string;
    password: string;
}

type propsType = {
    dispatch: Dispatch;
    services: InitStateServicesType,
    other: InitStateOtherType,
    popout: InitStatePopoutsType,
    success?: () => void;
    err?: () => void;
    // @ts-ignore
    history?: History<unknown>;
}

export const loginApp = (action: actionType, props: propsType) => {

    const {other, dispatch} = props;

    try {
        if (!other.isLoginBase && action.login && action.password) {
            // Запрос на сервер
            fetch(tokens.dbUrl, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}})
                .then(function (response) {
                    return response.json();
                })
                .then(data1 =>  {
                    const data = data1.result
                    //Поулчаем полученые id сервисов
                    const servicesId = data?.services?.access;

                    try {
                        //Получаем данные сервисов для firebase
                        let servicesData = data?.services?.data;
                        //Сохраняем данные в локалСторадж
                        localStorage.setItem('userHash', data?.hash)
                        localStorage.setItem('userLogin', action.login)
                        //Для удобного входа при следущем разе будет вставляться сразу в логин
                        localStorage.setItem('loginSave', action.login)
                        if (localStorage.getItem('videosit') === 'false') {
                            dispatch(setOther({
                                data: {
                                    videoSit: false
                                }
                            }))
                        }
                        //@ts-ignore
                        dispatch(setAccaunt({password: action.password, login: action.login}))
                        dispatch(setMe({
                            meData: {
                                email: action.login,
                                name: data?.name,
                                services: data.services.access,
                                avatar: data?.avatar,
                            }
                        }))

                        if (data.toString().indexOf('Error') > -1) {
                            if (data.toString().indexOf('ip') > -1) {
                                dispatch(setServicesID({data: false}))
                                let g = data.toString();
                                dispatch(setIp({
                                    data: {
                                        ip: g.split('>')[1],
                                    }
                                }))
                                setErrorPanel('ip', dispatch);
                            } else {
                                setErrorPanel('Проверте правельность ведденых данных', dispatch);
                                dispatch(setServicesID({data: false}))
                            }
                        } else {
                            dispatch(setStatusPop({
                                data: {
                                    type: 'services'
                                }
                            }))
                            dispatch(setServicesID({data: servicesId}))
                            dispatch(setServicesData({data: servicesData}))
                        }

                    } catch (e: any | unknown) {
                        console.warn(e)
                        if (e.toString().indexOf('ip') > -1) {
                            dispatch(setServicesID({data: false}))
                            setErrorPanel('ip', dispatch);
                        } else {
                            setErrorPanel('Проверте правельность ведденых данных', dispatch);
                            dispatch(setServicesID({data: false}))

                        }
                    }

                })
                .catch(function (error) {
                    console.warn(error)
                    setErrorPanel(error.toString(), dispatch)
                })


        } else {
            setErrorPanel('Ведите данные в инпуты', dispatch)
        }
    } catch (e: any | unknown) {
        console.warn(e)
        setErrorPanel(e.message, dispatch)
    }
}

export const loginAppAuto = (props: propsType) => {

    const urls = ['/horsebook', "/voron", "/vrchat", '/horsebook/stables', '/horsebook/shops', "/voron/horses", "/voron/request"]

    const {other, dispatch, err, success} = props;

    const urlTemp = window?.location?.pathname;
    // @ts-ignore
    console.log(urls.indexOf("/" + urlTemp.split('/').reverse()[0]) > -1)
    if (urls.indexOf("/" + urlTemp.split('/').reverse()[0]) > -1) {
        dispatch(setUrlSave({url: window?.location?.pathname}))
    } else {
        props.history.push("/")
    }

    try {
        if (!other.isLoginBase) {
            // Подготовка url запроса на сервер
            let url = tokens.dbUrl

            // Запрос на сервер
            fetch(url, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}})
                .then(response => response.json())
                .then(data1 => {
                    const data = data1.result
                    //Поулчаем полученые id сервисов
                    const servicesId = data?.services?.access;

                    try {
                        //Получаем данные сервисов для firebase
                        let servicesData = data?.services?.data;
                        //Сохраняем данные в локалСторадж
                        if (localStorage.getItem('videosit') === 'false') {
                            dispatch(setOther({
                                data: {
                                    videoSit: false
                                }
                            }))
                        }
                        //@ts-ignore
                        dispatch(setAccaunt({password: "", login: ""}))
                        dispatch(setMe({
                            meData: {
                                email: data?.email,
                                name: data?.name,
                                services: servicesId,
                                avatar: data?.avatar,
                            }
                        }))
                        // services.services = servicesId
                        // state.popouts = {
                        //     type: 'services'
                        // };
                        //Добавляю в базу данные для firebase
                        // state.servicesData = servicesData

                        if (data.toString().indexOf('Error') > -1) {
                            // if (data.toString().indexOf('ip') > -1) {
                            //     dispatch(setServicesID({data: false}))
                            //     let g = data.toString();
                            //     dispatch(setIp({data: {
                            //             ip: g.split('>')[1],
                            //         }}))
                            //     setErrorPanel('ip', dispatch);
                            // } else {
                            //     setErrorPanel('Проверте правельность ведденых данных', dispatch);
                            //     dispatch(setServicesID({data: false}))
                            // }
                            err && err();
                        } else {
                            // dispatch(setStatusPop({data: {
                            //         type: 'services'
                            //     }}))
                            dispatch(setServicesID({data: servicesId}))
                            dispatch(setServicesData({data: servicesData}))
                            success && success()
                        }


                    } catch (e: any | unknown) {
                        console.warn(e)
                        err && err()
                    }

                })
                .catch(function (error) {
                    console.warn(error)
                    err && err()
                })


        }
    } catch (e: any | unknown) {
        console.warn(e)
        props.err && props.err();
    }
}