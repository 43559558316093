import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const hbSlice = createSlice({
    name: 'hb',
    initialState,
    reducers: {
        // setProfile: (state, action: PayloadAction<{ profile: Partial<IProfile> }>) => {
        //     state.profile = { ...state.profile, ...action.payload.profile };
        // },
        setHB: (state, action) => ({
            ...action.payload
        })
    },
});

export const { setHB } = hbSlice.actions;

export default hbSlice.reducer;