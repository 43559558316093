import React, {useCallback, useEffect, useState} from "react";
import style
    from "../../../Componets/Popouts/VRChatPopouts/AddAssetsPopout/More/InputAddStable/InputAddStable.module.css";
import {Body} from "./styled";

type InputBlocksType = {
    type?: string,
    pattern?: string,
    hint?: string,
    isPaste?: boolean,
    defaultValue?: string[]
    onChange?: (texts: string[]) => void;
}

export const InputBlocks:React.FC<InputBlocksType> = ({type, defaultValue, pattern, hint, isPaste, onChange}) => {

    const [strings, setStrings] = useState<string[]>([]);
    const [value, setValue] = useState<string>("");

    const handlePaste = useCallback(() => {
        if (value) {
            setValue("")
        } else {
            navigator.clipboard.readText().then((text) => {
                console.log([...strings, text])
                setStrings(prev => ([...prev, text]));
                setValue("");
                onChange && onChange([...strings, text]);
            });
        }
    }, [strings]);

    const handleEnter = useCallback((e) => {
        if (e.target.value && e.code == "Enter" && !e?.altKey) {
            const text = e.target.value;
            console.log([...strings, text])
            setStrings(prev => ([...prev, text]));
            setValue("");
            onChange && onChange([...strings, text]);
        }
    }, [strings]);

    const handleDelete = useCallback((indexDelete) => {
        if (indexDelete !== -1) {
            setStrings(prev => prev.filter((data, index) => index !== indexDelete));
            onChange && onChange(strings);
        }
    }, [strings]);

    useEffect(() => {
        defaultValue && setStrings(defaultValue);
    }, [defaultValue]);

    return (
        <Body>
            {strings.length > 0 && <div className={"blocks"}>
                {strings.map((data, index) => (<div className={"blocks-item"}>
                    {data}
                    <div className={"blocks-item-delete"} onClick={() => handleDelete(index)}>❌</div>
                </div>))}
            </div>}
            <input
                min="3"
                pattern={pattern}
                value={value}
                type={type}
                placeholder={hint}
                onChange={
                    (e) => setValue(e.target.value)
                }
                onKeyPress={handleEnter}
            />
            {isPaste ? <div onClick={handlePaste} className={style.paste}><span>{value ? 'clear' : 'Paste'}</span></div> : ''}
    </Body>
)
};