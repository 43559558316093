import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {InitStateOtherType, ServicesSelectedType} from "../../types/types";

const initialState = {
    ip: false,
    isLoginBase: false,
    pass: false,
    servicesData: false,
    videoSit: true,
    urlSave: '',
    serviceSelected: '',
} as InitStateOtherType;

// export const getStatus = createAsyncThunk<object>(
//     'profile/me',
//     async (payload, { dispatch }) => {
//         try {
//             dispatch(setProfileStatus({ status: RequestState.LOADING }));
//             const profileResponse = await getProfileInfoApi();
//
//             if (profileResponse.status === 200) {
//                 dispatch(setProfileStatus({ status: RequestState.SUCCESS }));
//                 dispatch(
//                     setProfile({
//                         profile: profileResponse.data.result,
//                     }),
//                 );
//             }
//         } catch (err) {
//             dispatch(setProfileStatus({ status: RequestState.ERROR }));
//         }
//     },
// );

const otherSlice = createSlice({
    name: 'other',
    initialState,
    reducers: {
        // setProfile: (state, action: PayloadAction<{ profile: Partial<IProfile> }>) => {
        //     state.profile = { ...state.profile, ...action.payload.profile };
        // },
        setAccaunt: (state, action) => {
            state.pass = action.payload.password
            state.login = action.payload.login
        },
        setOther: (state, action: PayloadAction<{ data: InitStateOtherType }>) => {
            state = action.payload.data
        },
        setIp: (state, action: PayloadAction<{ data: {ip: string} }>) => {
            state.ip = action.payload.data.ip
        },
        setVideo: (state, action: PayloadAction<{ data: {video: boolean} }>) => {
            state.videoSit = action.payload.data.video
        },
        setUrlSave:  (state, action: PayloadAction<{ url: string }>) => {
            state.urlSave = action.payload.url;
        },
        setServiceSelected:  (state, action: PayloadAction<{ service: ServicesSelectedType }>) => {
            state.serviceSelected = action.payload.service;
        },
    },
});

export const { setOther, setIp, setAccaunt, setVideo, setUrlSave, setServiceSelected } = otherSlice.actions;

export default otherSlice.reducer;