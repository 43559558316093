import styled from "styled-components";

export const Body = styled.div<{isBlock?: boolean}>`
  background: rgba(199, 180, 222, 1);
  border: #f984ff 2px solid;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  justify-content: center;
  align-items: center;

  .title {
    color: ${props => props.isBlock ? "red" : "#fff"};
    font-size: 30px;
    font-style: normal;
  }

  .id {
    position: absolute;
    right: 0;
    color: #dedede;
    font-size: 14px;
  }

  .add-text {
    color: white;
    opacity: 1;
    position: center;
  }

  .add-block {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
  }

  .add-icon {
    position: absolute;
    right: 0;
    left: 0;
    justify-content: center;
    display: flex;
    opacity: 0;
    width: 100%;
    height: 30px;
    object-fit: contain;
  }

  .hover-actions {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% - 4px);
    width: 50%;
    background: rgba(199, 180, 222, 1);
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 10px;

    .icon {
      width: 30px;
      height: 30px;
    }
  }

  &:hover {
    .hover-actions {
      opacity: 1;
    }
    .id {
      opacity: 0;
    }

    .add-icon {
      opacity: 1;
    }

    .add-text {
      opacity: 0;
    }

  }
`;