import React from 'react';
import style from "../HorseBookView/HorseBook.module.css";
import imagesConfig from "../../../../configs/images-config";
import MenuBlockUI from '../../../../shared/ui/MenuBlockUI';
import {useSelector} from "react-redux";
import {selectOther} from "../../../../store/selectors";
import {ContentBlock} from "./ui/ContentBlock";
import {horseVetMenu} from '../../../../configs/menu-config'

type Types = {

}

export const HorseVET: React.FC<Types> = () => {

    const {pass, videoSit} = useSelector(selectOther);

    return (
      <div className={style.body}>
          <img
              className={style.imgBanner}
              src={imagesConfig.bgHorseVet}
              alt={'banner'}
          />
          {videoSit ? <video
              onLoadedMetadata={
                  // @ts-ignore
                  (event) => document.getElementById('videoHorseVET').style.visibility = 'visible'
              } className={style.video}
              id={'videoHorseVET'}
              loop
              autoPlay
              muted
              playsInline
              poster={imagesConfig.bgHorseVet}
          >
              <source src={'hhttps://youtu.be/FFwPf_Norvk'}
                      type="video/mp4"/>
          </video> : ''}
          <div className={style.panel}>
              <ContentBlock/>
              <MenuBlockUI
                  menuColor={"#d1f5e8"}
                  buttonColor={"#2ed7be"}
                  list={horseVetMenu}
              />
          </div>
      </div>
  )
}