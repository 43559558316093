import styled from "styled-components";

export const Body = styled.div <{ color: string }>`
  width: 100vw;
  height: 10vh;
  background: ${props => props.color};
  border-radius: 0 0 20px 20px;
  position: absolute;
  top: 0;
  z-index: 2;
  display: grid;
  grid-template-columns: 15% 70% 15%;

  img {
    height: 9vh;
  }

  .block-user {
    position: absolute;
    right: 10px;
    height: calc(10vh - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;

    .img-avatar {
      object-fit: cover;
      height: calc(10vh - 20px);
      width: calc(10vh - 20px);
      border-radius: 100%;
    }
  }

  //img:hover {
  //  
  //}

  @media (max-width: 600px) {
    height: 5%;
    img {
      height: 4vh;
      padding: 5px;
      display: flex;
      justify-content: right;
    }

    .block-user {
      position: absolute;
      right: 0px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 0;
      
      .title-user {
        visibility: hidden;
      }

      .img-avatar {
        object-fit: cover;
        height: calc(6vh - 20px);
        width: calc(6vh - 20px);
        border-radius: 100%;
      }
    }
  }
`;