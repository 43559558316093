import React from "react";
import ButtonItem from "./ButtonItem/ButtonItem";

import style from './PageSwitch.module.css';

const PageSwitch = (props) => {

    function onNext() {
        if (props.maxPage >= props.page + 1) {
            props.setPage(props.page + 1);
        }
    }

    function onBack() {
        if (props.page - 1 > -1) {
            props.setPage(props.page - 1);
        }
    }

    return (
        <div className={style.body}>
            <ButtonItem key={'back'} onClick={onBack} text={'<'} />
            <ButtonItem key={'count'} onClick={() => {}} text={props.page} />
            <ButtonItem key={'>'} onClick={onNext} text={'>'} />
        </div>
    )
};

export default PageSwitch;