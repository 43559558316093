import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import urlConfig from "../../configs/url-config";
import {InitStateServicesType} from "../../types/types";


const initialState = {
    services: false,
    servicesList: [
        {text: 'Horse Book', url: urlConfig.services[0]},
        {text: 'Voron', url: urlConfig.services[1]},
        {text: 'LimboBot', url: urlConfig.services[2]},
        {text: 'VRChat', url: urlConfig.services[3]},
        {text: 'HorseVet', url: urlConfig.services[4]}
    ],
    servicesProp: false,
} as InitStateServicesType;

// export const getStatus = createAsyncThunk<object>(
//     'profile/me',
//     async (payload, { dispatch }) => {
//         try {
//             dispatch(setProfileStatus({ status: RequestState.LOADING }));
//             const profileResponse = await getProfileInfoApi();
//
//             if (profileResponse.status === 200) {
//                 dispatch(setProfileStatus({ status: RequestState.SUCCESS }));
//                 dispatch(
//                     setProfile({
//                         profile: profileResponse.data.result,
//                     }),
//                 );
//             }
//         } catch (err) {
//             dispatch(setProfileStatus({ status: RequestState.ERROR }));
//         }
//     },
// );
const servicesSlice = createSlice({
    name: 'servicesData',
    initialState: initialState,
    reducers: {
        setServicesData: (state, action: PayloadAction<{data: any}>) => ({
            ...state,
            servicesProp: action.payload.data,
        }),
        setServicesID: (state, action: PayloadAction<{ data: any}>) => ({
            ...state,
            services: action.payload.data ? action.payload.data.split('') : false
        })
    },
});

export const { setServicesData, setServicesID } = servicesSlice.actions;

export default servicesSlice.reducer;