import React, {useEffect} from 'react';
import {Redirect, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setAccaunt} from "../../../../store/reducers/otherReducer";
import {LogoutServerAction} from "../../../../backend/LimboServer/logout";
import {closeStatusPop, setStatusPop} from "../../../../store/reducers";
import errorPopout from "../../../Popouts/ErrorPopout/ErrorPopout";
import popoutsConfig from "../../../../configs/popouts-config";
import {setMe} from "../../../../store/reducers/meReducer";

type Types = {

}

export const LogoutView: React.FC<Types> = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    LogoutServerAction({
      logoutSuc: () => {
        dispatch(setAccaunt({password: "", login: ""}));
        dispatch(setMe({meData: {email: false, name: false, services: false, avatar: false}}));
        dispatch(closeStatusPop());
        localStorage.clear();
        history.push('/');
      },
      logoutErr: () => {
        dispatch(setStatusPop({
          data: {
            text: "Произошла ошибка!",
            type: popoutsConfig.error,
          }
        }));
      }
    })

  }, [])


  return (
      <div />
  )
}
