import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Banner, Body, EmailUser, GridServices, LogoutButton, TitleUser} from './styled';
import {selectMe, selectServicesData} from "../../../store/selectors";
import {AvatarUI} from "../../../shared/ui/Avatar";
import {useHistory} from "react-router-dom";

type Types = {
    st?: string | boolean;
    close: () => void;
}

export const ProfilePopout: React.FC<Types> = ({st, close}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {avatar, name, email} = useSelector(selectMe);
    const {services} = useSelector(selectServicesData);

    const onLogout = () => {
      history.push("/logout");
    }

    if (st === 'profile') {
        return (
            <Body onClick={e => e.stopPropagation()}>
                <Banner><AvatarUI className={'avatar-img'} size={150} src={avatar ? avatar : ''}/> </Banner>
                <TitleUser>{name}</TitleUser>
                <EmailUser>{email || 'Не указан'}</EmailUser>
                <LogoutButton onClick={onLogout}>Выйти</LogoutButton>
                <GridServices>
                    {services}
                </GridServices>
            </Body>
        )
    } else {
        return (<div/>)
    }
}