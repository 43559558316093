import React, {useEffect, useLayoutEffect, useState} from 'react';
import InputLogin from "./ui/InputLogin/InputLogin";
import ButtonLogin from "./ui/ButtonLogin/ButtonLogin";
import TextLogin from "./ui/TextLogin/TextLogin";
import {useDispatch, useSelector} from "react-redux";
import {selectOther, selectPopout, selectServicesData, selectMe} from "../../../../store/selectors";
import {loginApp, loginAppAuto} from "../../../../store/other/loginApp";
import {InitStateServicesType} from "../../../../types/types";
import {Body} from './styled';

type PropsI = {
    props: {status: string;}
}

const LoginView: React.FC<PropsI> = ({props}) => {

    const [loginInput, setLoginInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [buttonText, setButtonText] = useState('Log In')
    const [isLoading, setIsLoading] = useState(false)

    const other = useSelector(selectOther);
    const me = useSelector(selectMe);
    const services: InitStateServicesType = useSelector(selectServicesData);
    const popout = useSelector(selectPopout);

    const dispatch = useDispatch();

    useEffect(() => {
        let loginSave = localStorage.getItem('loginSave')
        if (loginSave) {
            setLoginInput(loginSave)
        }
    }, [])



    const logIn = () => {
        setIsLoading(true)
        setButtonText('Loading...');
        loginApp({
            login: loginInput,
            password: passwordInput
        }, {
            services: services,
            popout: popout,
            dispatch: dispatch,
            other: other,
            success: () => {
                setIsLoading(false);
                setButtonText('Success')
            },
            err: () => {
                setIsLoading(false);
                setButtonText('Log In');
            },
        })
    }
    console.log(me)

    return(
        <Body isBlurPanel={isLoading || props.status == "loading"} >
            {!(props.status == "loading")
                ? props.status == "success"
                    ? (<div className={'panel'}>Привет, {me?.name}</div>)
                    : (<div className={'panel'}>
                <TextLogin text={'Login panel'} type={'email'}/>
                <InputLogin text={loginInput} set={setLoginInput} hint={'email'}/>
                <InputLogin text={passwordInput} set={setPasswordInput} hint={'password'} type={'password'}/>
                <ButtonLogin text={buttonText} onClick={logIn}/>
            </div>)
                : <div className={'panel'}>Загрузка</div>}
        </Body>
    )
};

export default LoginView;