import React, {useState} from "react";
import imagesConfig from "../../../../../../configs/images-config";

import style from './InputBlock.module.css';

const InputBlock = (props) => {

    const [sendTrue, setSendTrue] = useState(false);

    return (
        <div className={style.body}>
            <div className={style.input}><input onChange={(event => setSendTrue(!!event.target.value))} placeholder={'command'}/></div>
            <div className={style.go}><img src={sendTrue ? imagesConfig.sendCommandGradient : imagesConfig.powerGradient } onClick={props.onClick} alt={'switch'}/></div>
        </div>
    )
};

export default InputBlock;