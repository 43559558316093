import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {store} from "./data/store/store";
import {Provider} from "react-redux";


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            {/*<div/>*/}
            <App dispatch={() => {}}/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);