import {URL_BACKEND} from "../../../../../../../../../configs/url-config";
import {dataUserPlusType} from "../../PlusBlock/model/getUsersPlus";

type handlesaveActionType = {
   version: string,
   description: string,
   download: string,
   onSuccessCallback: () => void,
   onErrorCallback: () => void,
}

export const handlesaveAction = ({description, download, version, onErrorCallback, onSuccessCallback}: handlesaveActionType) => {
   const data = {
      version: version,
      description: description,
      download: download
   }

   fetch(`${URL_BACKEND}/vrcat/version`, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}, method: 'POST', body: JSON.stringify(data)})
       .then(function (response) {
          return response.json();
       })
       .then(function (res) {
          const tmp: dataUserPlusType[] = []
          const data = res.result;
          onSuccessCallback();
       })
       .catch(function (error) {
          onErrorCallback();
       })
}