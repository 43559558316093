import {URL_BACKEND} from "../../../../../../../../../configs/url-config";
import {dataUserPlusType} from "../../PlusBlock/model/getUsersPlus";

type getversionActionType = {
    onSuccessCallback: (e: {version: string, description: string, download: string}) => void;
    onErrorCallback: () => void;
}

export const getversionAction = ({onErrorCallback, onSuccessCallback}: getversionActionType) => {
    fetch(`${URL_BACKEND}/vrcat/version`, {mode: "cors", headers: {"Access-Control-Allow-Origin": "*"}, method: 'GET'})
        .then(function (response) {
            return response.json();
        })
        .then(function (res) {
            const data = res.result;
            if (res.status == "success") {
                onSuccessCallback({
                    version: data.version,
                    download: data.download,
                    description: data.description
                });
            } else {
                onErrorCallback();
            }
        })
        .catch(function (error) {
            onErrorCallback();
        })
}