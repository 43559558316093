import React from 'react';

import style from './CityItem.module.css';

const CityItem = (props) => {

    return (
        <div onClick={props.onClick} key={props.text + '_key'} className={style.body}>
            {props.sel ?
                <h4 key={props.key + '_span'} >{props.text}</h4>:
                <span key={props.key + '_span'}>{props.text}</span>
            }
        </div>
    )
};

export default CityItem;