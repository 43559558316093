import styled from "styled-components";
import {NavLink} from "react-router-dom";

export const Body = styled.div`
  text-align: center;
  padding: 8px;
  height: 100%;

  h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    background-color: #6ca856;
    border-radius: 10px;
    outline: none;
  }

  @media (max-width: 900px) {

    padding: 0;
    width: calc(100% / 5.1);

    h4 {
      font-size: calc(100% / 1.2);
    }
  }
`

export const Nav = styled(NavLink)`
  & {
    color: white;
    text-decoration: none;

    h4 {
      color: #b37fd9;
      background: #cdcdcd;
      border: #B37FD9FF solid 5px;
    }
    
    
  }

  .activeMenu {
    h4 {
      color: #b37fd9;
      background: #B37FD9FF;
      border: #B37FD9FF solid 5px;
    }
  }
`

