import firebaseData from "../../backend/firebase";
import {Dispatch} from "react";
import {firebaseConfigHB} from "../../configs/firebase-config";
import {setHB} from "../reducers";

export const loginHB = async (dispatch: Dispatch<any>, servicesProp: any, pass: string) => {
    if (servicesProp) {
        const dataL =servicesProp.split(',')

        let config = {
            apiKey: dataL[0],
            authDomain: dataL[1],
            databaseURL: dataL[2],
            projectId: dataL[3],
            storageBucket: dataL[4],
            messagingSenderId: dataL[5],
            appId: dataL[6]
        };

        try {
            firebaseData.start(config, pass);

            const stable = firebaseData.get(firebaseConfigHB.stableDataHB);
            const shops = firebaseData.get(firebaseConfigHB.shopsDataHB);

            dispatch(setHB({stables: stable, shops: shops}))
        }catch (e) {
            firebaseData.restart(config, pass, () => {
                const stable = firebaseData.get(firebaseConfigHB.stableDataHB);
                const shops = firebaseData.get(firebaseConfigHB.shopsDataHB);

                dispatch(setHB({stables: stable, shops: shops}))
            });
        }
    }


}