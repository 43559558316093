import React from "react";
import Lottie from 'react-lottie';
import lottieConfig from '../../../configs/lottie-config';

import style from './ErrorPoppout.module.css';
import {textConfig} from "../../../configs/text-config";

type ErrorPopoutPropsType = {
    st?: boolean | string;
    text?: string;
    onCopy?: () => void;
}

const ErrorPopout:React.FC<ErrorPopoutPropsType> = ({text, st, onCopy}) => {

    if (st === 'error') {
        return (
            <div className={style.body}>
                <h4>Error</h4>
                <div className={style.errorBlock}>
                    <Lottie
                        width={'40%'}
                        height={'100%'}
                        style={{width: '40%'}}
                        options={lottieConfig.errorConfig}
                    />
                    <span>{text}</span>
                </div>
            </div>
        )
    } else if (st === 'errorIP') {
        return (
            <div className={style.bodyIP}>
                <h4 className={style.title}>Error</h4>
                <span>{textConfig.popoutsText.denyIp}</span>
                <div className={style.bgButton}>
                    <div />
                    <h4 className={style.copy} onClick={onCopy}>{textConfig.buttonsText.copyIp}</h4>
                </div>
            </div>
        )
    } else {
        return (<div />);
    }
};

export default ErrorPopout;