import styled from "styled-components";

export const Body = styled.div `
  background: #cdcdcd;
  padding: 8px;
  border-radius: 10px;
  margin: 0 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 100%;
    outline: none;
    border-radius: 20px;
    border: none;
    text-align: left;
    padding-left: 10px;
  }

  input:invalid {
    outline: 1px red;
    border: 1px red;
  }

  .blocks {
    flex-shrink: 0;
    display: flex;
    flex-wrap: nowrap;
    max-width: 60%;
    overflow: hidden;
    padding-right: 7px;

    .blocks-item {
      position: relative;
      background: #e1e1e1;
      padding: 2px 4px;
      padding-right: 30px;
      border-radius: 10px;
      margin-right: 5px;

      .blocks-item-delete {
        cursor: pointer;
        position: absolute;
        right: 3px;
        top: 0;
      }
    }
  }

  .paste {
    width: 10vw;
    background-size: contain;
    color: white;
    background: #d9d8d8 center;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 5px;
  }
`