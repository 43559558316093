import React from "react";

import style from './ButtonAddStable.module.css';

const ButtonAddStable = (props) => {

    return (
        <div className={style.body} onClick={props.onClick} style={{width: props.xl ? '100%' : ''}}>
            <span>{props.text}</span>
        </div>
    )
};

export default ButtonAddStable;