import styled from "styled-components";

export const Body = styled.div<{ listLen: number, menuColor: string }>`
  width: 20vw;
  height: auto;
  padding: 8px;
  border-radius: 0 10px 20px 0;
  background-color: ${props => props.menuColor};
  display: grid;
  grid-template-rows: repeat(calc(100% / ${props => props.listLen}), ${props => props.listLen});

  @media (max-width: 900px) {
    width: 100%;
    height: 5vh;
    padding: 0;
    /*display: grid;*/
    /*grid-template-columns: calc(100%/5) calc(100%/5) calc(100%/5) calc(100%/5) calc(100%/5);*/
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    border-radius: 20px 20px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const BodyButton = styled.div<{color: string, colorSelected: string}>`
  text-align: center;
  padding: 8px;

  h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    background-color: ${props => props.color};
    border-radius: 10px;
    outline: none;
  }

  .nav {
    color: white;
    text-decoration: none;
  }

  .navActiv h4 {
    color:  ${props => props.color};
    background: ${props => props.colorSelected};
    border:  ${props => props.color} solid 5px;
  }

  @media (max-width: 900px) {
    padding: 0;
    width: calc(100% / 5.1);
    h4 {
      font-size: calc(100% / 1.2);
    }
  }
`;