import React from "react";
import ItemStable from "./ItemStable/ItemStable";

import style from './ListStables.module.css';

const ListStables = (props) => {

    let list = [];

    //Прогрузка 4 конюшни
    try {
        for (let i = 0; i < 4; i++) {
            list.push(props.list[props.selected][props.page * 4 + i]);
        }
    }catch (e) {
        list = []
    }


    return (
        <div className={style.body}>
            {list.map((stable, index) => (
                stable ? <ItemStable key={index} onClick={() => {
                    props.show(index);
                }} name={stable.name} image={stable.pic1} /> : ''
            ))}
        </div>
    )
};

export default ListStables;