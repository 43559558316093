import styled from "styled-components";

export const Body = styled.div<{size: number}>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`