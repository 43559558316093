import React from "react";
import HorseItem from "./HorsesItem/HorseItem";

import style from './HorsesList.module.css';

const HorsesList = (props) => {
    
    let list = [];

    try {
        for (let i = 0; i < 4; i++) {
            list.push(props.list[props.page * 4 + i]);
        }
    }catch (e) {
        list = [];

    }

    //crteate id element
    let id = 0;

    return (
        <div className={style.body}>
            {list.map((data, index) => {

                //set id in let because return command
                let j = id;
                id++

                console.log(list)
                return (
                    data ? <HorseItem onClick={() => props.onClick(j)} name={data.name} key={data.key} hide={data.hide} image={data.image}/> : ''
                )
            })}
        </div>
    )
};

export default HorsesList;