import React from "react";
import InputAddShop from "../InputAddShop/InputAddShop";
import {textConfig} from "../../../../../../configs/text-config";
import ButtonAddShop from "../ButtonAddShop/ButtonAddShop";

import style from './Slides.module.css';

const Slide0 = (props) => {

    const onNext = () => {
        props.next()
    }

    return (
        <div className={style.body}>
            <InputAddShop
                value={props.name}
                setValue={props.setName}
                hint={textConfig.popoutsText.shopInputName}
                paste={true}
            />
            <InputAddShop
                value={props.phone}
                setValue={props.setPhone}
                hint={textConfig.popoutsText.shopInputPhone}
                paste={true}
            />
            <InputAddShop
                value={props.web}
                setValue={props.setWeb}
                hint={textConfig.popoutsText.shopInputWeb}
                paste={true}
            />
            <InputAddShop
                value={props.des}
                setValue={props.setDes}
                hint={"des "}
                paste={true}
            />
            <InputAddShop
                value={props.email}
                setValue={props.setEmail}
                hint={textConfig.popoutsText.shopInputEmail}
                paste={true}
            />
            <InputAddShop
                value={props.photo}
                setValue={props.setPhoto}
                hint={textConfig.popoutsText.shopInputPhoto}
                paste={true}
            />
            <ButtonAddShop
                onClick={onNext}
                text={textConfig.popoutsText.shopButtonNext}
            />
        </div>
    )
};

export default Slide0;