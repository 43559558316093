import styled from "styled-components";

export const Body = styled.div `
  background-color: white;
  border-radius: 20px 20px 0 0 ;
  width: 100vw;
  max-width: 600px;
  height: 50%;
  text-align: center;
  position: relative;
  padding: 8px;
`