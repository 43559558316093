import React, {useState} from "react";
import popoutsConfig from "../../../../configs/popouts-config";
import Slide0 from "./More/Slides/Slide0";
import {textConfig} from "../../../../configs/text-config";
import Slide1 from "./More/Slides/Slide1";
import imagesConfig from "../../../../configs/images-config";

import style from './AddShopPopouts.module.css';
import {InitStateShop} from "../../../../types/types";

type AddShopPopoutsPropsType = {
    alert: (text: string, image: string) => void;
    addShop: (data: InitStateShop) => void;
    st?: boolean | string;
}

const AddShopPopouts: React.FC<AddShopPopoutsPropsType> = (props) => {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState('');
    const [web, setWeb] = useState('');
    const [email, setEmail] = useState('');
    const [des, setDes] = useState('');
    const [photo, setPhoto] = useState('');
    const [slide, setSlide] = useState(0)

    function onNext() {
        if (slide === 0) {
            //check data inputs
            if (name && phone && web && email && des && photo) {
                setSlide(1);
            }else {
                props.alert(textConfig.popoutsText.shopErrorEmpty, imagesConfig.empty);
            }
        }else {
            let addShopData: InitStateShop = {
                val(): any {
                },
                name: name,
                ph: photo,
                tel: phone,
                url: web,
                email: email,
                text: des
            };

            props.addShop(addShopData);
        }
    }

    if (props.st === popoutsConfig.hbAddShop) {
        return (
            <div
                className={style.body}
                onClick={(e) => e.stopPropagation()}
            >
                {slide ?
                    <Slide1
                        name={name}
                        phone={phone}
                        web={web}
                        email={email}
                        des={des}
                        photo={photo}
                        next={onNext}
                        back={() => setSlide(0)}
                    /> :
                    <Slide0
                        name={name} setName={setName}
                        phone={phone} setPhone={setPhone}
                        web={web} setWeb={setWeb}
                        wmail={email} setEmail={setEmail}
                        des={des} setDes={setDes}
                        photo={photo} setPhoto={setPhoto}
                        next={onNext}
                    />
                }
            </div>
        )
    } else {
        return (<div />)
    }
};

export default AddShopPopouts;