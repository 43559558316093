import styled from "styled-components";

export const Body = styled.div `
  & {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .card {
      width: 90%;
      height: 90%;
      position: relative;
      border-radius: 20px;
      object-fit: contain;
      background-size: cover;
      background-position: center;

      img {
        width: 50px;
        height: 10%;
        object-fit: contain;
      }
    }
    .edit {
      position: absolute;
      top: 10px;
      right: 10px;
      width: calc(5%);
      min-width: 10%;
      display: flex;
      justify-content: right;
      align-items: center;
      background: white;
      border-radius: 20px;
      gap: 6px;
      padding: 5px;
      cursor: pointer;
      pointer-events: all;

      img {
        width: 16px;
        height: 16px;
      }
      
      span {
        opacity: 0;
      }
      
      @media (min-width: 500px) {
        min-width: 20%;
        span {
          opacity: 1;
        }
      }
    }
    .fav {
      min-width: 20%;
      display: flex;
      justify-content: left;
      align-items: center;
      background: white;
      border-radius: 20px;
      gap: 6px;
      padding: 5px;
      position: absolute;
      top: 10px;
      left: 10px;
      
      img {
        width: 16px;
        height: 16px;
      }
    }

    .bg {
      width: 100%;
      height: 50%;
      border-radius: 20px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(5,5,32,0.5) 24%, rgba(0,212,255,0) 100%);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      color: white;
    }
  }
  
`;