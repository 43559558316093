import styled from "styled-components";

export const Body = styled.div `
  background-color: white;
  border-radius: 20px 20px 0 0;
  width: 100vw;
  max-width: 600px;
  height: 30vh;
  text-align: center;
  padding: 8px;


  .logout {
    background-color: red;
  }
  h4 {
    width: 100%;
    background-color: aqua;
    color: white;
    border-radius: 10px;

  }
`;