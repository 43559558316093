import React from "react";
import {Redirect, Route} from "react-router-dom";
import EmptyBlock from "./Blocks/EmptyBlock/EmptyBlock";
import HorsesBlock from "./Blocks/HorsesBlock/HorsesBlock";
import RequestBlock from "./Blocks/RequestsBlock/RequestBlock";

import style from './ContentBlock.module.css';

const ContentBlock = (props) => {

    return (
        <div className={style.body}>
            <Route exact path={'/voron'}><EmptyBlock /></Route>
            <Route path={'/voron/horses'}><HorsesBlock dispatch={props.dispatch} /></Route>
            <Route path={'/voron/request'}><RequestBlock dispatch={props.dispatch} /></Route>
            <Redirect to={'/voron'} />
        </div>
    )
};

export default ContentBlock;