import React, {createRef, useCallback, useEffect, useRef} from 'react';
import { BodyList } from './styled';
import {dataUserPlusType} from "../../model/getUsersPlus";
import {ItemUserPlus} from "../ItemUserPlus";
import {useDispatch} from "react-redux";
import {setStatusPop} from "../../../../../../../../../../store/reducers";
import popoutsConfig from "../../../../../../../../../../configs/popouts-config";

type Types = {
  users: dataUserPlusType[];
  isApp?: boolean;
  onPage?: (page: string | number) => void;
    page?: string | number;
}

export const ListUserPlus: React.FC<Types> = (
    {
        users,
        isApp,
        onPage,
        page,
    }
) => {
    const dispatch = useDispatch();

    const handleAddModal = useCallback(() => {
        dispatch(setStatusPop({
            data: {type: popoutsConfig.vrchatAddPlus}
        }))
    }, []);

    const handleClick = (id: string) => () => {
      isApp && window.open(`https://vrchat.com/home/user/${id}`, "_blank");
    }

    const handleScroll = () => {
        if (!!onPage && typeof page == "number") {
            console.log("page")
            onPage(page + 1);
        }
    }

    return (
    <BodyList>
      {users.map((data, index) => (
          <ItemUserPlus
            key={index}
            name={!isApp ? data.name : data.id}
              // @ts-ignore
            id={isApp ? data.time : ""}
            isHover={!isApp}
              // @ts-ignore
            isBlock={isApp ? data.isBlock : false}
            onClick={handleClick(data.id)}
          />
      ))}
        <ItemUserPlus
            isAdd={!isApp}
            isLoad={isApp}
            onClick={() => isApp ? handleScroll() : handleAddModal()}
        />
    </BodyList>
  )
}