import {RootState} from "./store";

export const selectRoot = (state: RootState) => state;
export const selectAlert = (state: RootState) => state.alert;
export const selectOther = (state: RootState) => state.other;
export const selectMe = (state: RootState) => state.me;
export const selectVoron = (state: RootState) => state.voron;
export const selectServicesData = (state: RootState) => state.servicesData;
export const selectPopout = (state: RootState) => state.popout;
export const selectHB = (state: RootState) => state.hb;
export const selectVRChat = (state: RootState) => state.vrchat;