import React from "react";
import ImagesAddStable from "../ImagesAddStable/ImagesAddStable";
import ButtonAddStable from "../ButtonAddStable/ButtonAddStable";

import style from './Slides.module.css';
import TextAddStable from "../TextAddStable/TextAddStable";

const Slide2 = (props) => {

    return (
        <div className={style.body}>
            <ImagesAddStable
                pic1={props.pic1}
                pic2={props.pic2}
                pic3={props.pic3}
            />
            <TextAddStable title={true} text={props.name} />
            <TextAddStable text={'des: ' + props.des} />
            <TextAddStable text={ 'price: ' + props.cost + '₽ tel:' +  props.phone} />
            <TextAddStable text={ 'address: ' + props.address + 'note:' +  props.note} />
            <TextAddStable
                url={'https://yandex.ru/maps/?rtext=' + props.map1 + ',' + props.map2}
                text={ 'map: ' + props.map1 + ',' +  props.map2}
            />
            <div style={{display: 'flex'}}>
                <ButtonAddStable xl={true} text={'< Back'} onClick={props.back} />
                <ButtonAddStable xl={true} text={'Add stable >'} onClick={props.next} />
            </div>
        </div>
    )
};

export default Slide2