import React from 'react';
import { Body } from './styled';

type IconType = {
    src: string;
    size?: number;
    onClick?: () => void;
    className?: string;
};

const Icon: React.FC<IconType> = ({src, size = 50, onClick, className}) => {

 return (
  <Body className={className} onClick={onClick} size={size}>
      <img alt={"img"} src={src}/>
  </Body>
 );
};

export default Icon;