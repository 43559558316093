import React from "react";
import {useDispatch, useSelector} from "react-redux";
import imagesConfig from "../../configs/images-config";
import Lottie from 'react-lottie';

import style from './AlertBanner.module.css';
import {selectAlert} from "../../store/selectors";
import {InitStateAlertType} from "../../types/types";
import {closeAlertAction} from "../../store/other/openCloseAlert";

type AlertBannerPropsType = {

}

const AlertBanner: React.FC<AlertBannerPropsType> = () => {

    const status: InitStateAlertType = useSelector(selectAlert);

    const dispatch = useDispatch();

    //get info status for alertBanner [data, close pop]
    let close = () => {
        closeAlertAction({dispatch: dispatch})
    };

    return (
        <div className={style.body}>
            {typeof status !== 'boolean' ? <div className={style.panel} onClick={close}>
                {typeof status.lottie !== 'boolean' && typeof status.lottie !== 'undefined' ?
                    <Lottie
                        width={'20%'}
                        height={'90%'}
                        style={{position: 'absolute'}}
                        options={status.lottie}
                    />
                    :
                    // @ts-ignore
                    <img src={status.image ? status.image : imagesConfig.errorAlert} alt={'alertBanner'}/>}
                <h4>{status.text}</h4>
            </div> : ''}
        </div>
    )
};

export default AlertBanner