import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px;
  background: #cdcdcd;
  border-radius: 5px;
  padding-left: 10px;
  
  
  .title {
    text-align: start;
    font-size: 20px;
    min-width: 200px;
  }
  .input {
    border: none;
    outline: none;
    border-radius: 10px;
    background: white;
    padding: 10px;
    width: 100%;
    color: black;
  }
`;