import React from "react";

import style from './ButtonMenu.module.css';
import {NavLink} from "react-router-dom";

const ButtonMenu = (props) => {

    //Buttons for menu horsebook
    return (
        <div className={style.body}>
            <NavLink className={style.nav} activeClassName={style.navActiv} to={'/voron/' + props.path}>
                <h4>{props.text}</h4>
            </NavLink>
        </div>
    )
};

export default ButtonMenu;