import React from 'react';
import {ErrorItemBody} from "../../styled";
import Icon from "../../../../../../../../../../shared/ui/Icon";
import profileIcon from "../../../../../../../../../../shared/media/images/profile.png";
import deleteIcon from "../../../../../../../../../../shared/media/images/delete.png";

type ItemErrorType = {
    id: string,
    error: string,
};

const ItemError: React.FC<ItemErrorType> = ({id, error}) => {

    const onClickProfile = () => {
      window.open(`https://vrchat.com/home/user/${id}`, "_blank");
    }
    const onClickDelete = () => {
      window.open(`https://vrchat.com/home/user/${id}`, "_blank");
    }

    return (
        <ErrorItemBody>
            <div className={'name'}><span>{id}</span></div>
            <div className={'error'}><span>{error}</span></div>
            <div className={'hover'}>
                <Icon
                    onClick={onClickProfile}
                    size={40}
                    src={profileIcon}
                />
                <Icon
                    className={'delete-icon'}
                    onClick={onClickDelete}
                    size={35}
                    src={deleteIcon}
                />
            </div>
        </ErrorItemBody>
    );
};

export default ItemError;