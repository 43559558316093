import React from "react";
import ButtonMenu from "./More/ButtonMenu/ButtonMenu";

import style from './MenuBlock.module.css';

const MenuBlock = (props) => {

    //left menu in horsebook view
    return (
        <div className={style.body}>
            <ButtonMenu text={'Товары'} path={'tovars'}/>
            <ButtonMenu text={'Конюшни'} path={'stables'} />
            <ButtonMenu text={'Ветеринары'} path={'veterinary'} />
            <ButtonMenu text={'Магазины'} path={'shops'} />
            <ButtonMenu text={'Worker'} path={'worker'} />
        </div>
    )
};

export default MenuBlock;