import React, {useCallback, useEffect, useState} from 'react';
import {BlockSwitch, Body} from './styled';

type Types = {
    value?: boolean;
    onChange: (on: boolean) => void;
    title?: string;
    color?: string;
}

export const SwitchUI: React.FC<Types> = (
    {
        color,
        title,
        onChange,
        value,
    }
) => {

    const [on, setOn] = useState(false);

    useEffect(() => {
        value && setOn(value)
    }, [value]);

    const handleSwitch = useCallback(() => {
        setOn(!on);
        onChange(!on);
    }, [on])

    return (<BlockSwitch>
            <span>{title}</span>
            <Body onClick={handleSwitch} on={on} color={color} >
                <div className={'slider'} />
                <div className={'back'} />
            </Body>
    </BlockSwitch>

    )
}